import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './workspace-settings.scss';
import { Box, Flex, Tabs, Text } from '@radix-ui/themes';
import { Subscription } from './components/view-wrapper/Subscription';
import { paymentsApi } from '@/services/payments';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export const WorkspaceSettings = () => {
  const userRendersData = paymentsApi.useGetUserRendersQuery();
  const isAppPro = useFeatureFlagEnabled('app-pro');

  return (
    <Flex
      className="view-wrapper-workspace-settings"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | Account</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex className="header" px={'5'} py={'3'} align={'center'}>
        <Text weight={'bold'} size={'5'}>
          Account
        </Text>
      </Flex>
      {/* <Tabs.Root value={tabValue} onValueChange={(val) => onTabChange(val)}> */}
      <Tabs.Root defaultValue={'Profile'}>
        <Box px={'6'}>
          <Tabs.List>
            <Tabs.Trigger value="Profile">Profile</Tabs.Trigger>
            {(userRendersData.data?.data[0]?.interval !== 'free' ||
              isAppPro) && (
              <Tabs.Trigger value="Subscription">Subscription</Tabs.Trigger>
            )}
          </Tabs.List>
        </Box>

        <Box mt={'4'}>
          <Tabs.Content value="Profile">
            <ViewWrapper />
          </Tabs.Content>
          {(userRendersData.data?.data[0]?.interval !== 'free' || isAppPro) && (
            <Tabs.Content value="Subscription">
              <Subscription />
            </Tabs.Content>
          )}
        </Box>
      </Tabs.Root>
    </Flex>
  );
};
