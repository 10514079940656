import './view-wrapper.scss';
import { Text, ScrollArea, Flex, Badge } from '@radix-ui/themes';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { useAppSelector } from '@/hooks';
import { paymentsApi } from '@/services/payments';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export const Subscription = () => {
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const userRendersData = paymentsApi.useGetUserRendersQuery();
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const goToBillingPortal = () => {
    window.open(
      `https://billing.stripe.com/p/login/cN2dU7f840IgafmaEE?prefilled_email=${currentUser?.email}`,
      '_blank'
    );
  };

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{ height: 'calc(100vh - 133px)' }}
    >
      <Flex
        className="subscription-wrapper"
        direction={'column'}
        gap={'3'}
        px={'6'}
      >
        <Flex className="name-wrapper" direction={'column'} gap={'2'}>
          <Text className="name" weight={'regular'} size={'2'}>
            Subscription type:{' '}
            {userRendersData.data?.data[0]?.interval !== 'free' && (
              <Badge color="purple">PREMIUM</Badge>
            )}
            {isAppPro && userRendersData.data?.data[0]?.interval === 'free' && (
              <Badge color="amber">PRO</Badge>
            )}
            {userRendersData.data?.data[0]?.interval === 'free' &&
              !isAppPro && <Badge color="blue">FREE</Badge>}
          </Text>
        </Flex>
        <Flex align={'center'} gap={'1'}>
          <Text
            color="blue"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
            onClick={() => goToBillingPortal()}
          >
            Billing Dashboard <ExternalLinkIcon style={{ cursor: 'pointer' }} />
          </Text>
        </Flex>
      </Flex>
    </ScrollArea>
  );
};
