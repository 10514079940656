import './view-wrapper.scss';
import { Box } from '@radix-ui/themes';
import { pageSlug } from '../../Workspace';
import { WorkspaceSettings } from '../workspace-settings/WorkspaceSettings';
import { MockupCatalog } from '../catalog/MockupCatalog';
import { MockupTemplates } from '../mockup-templates/MockupTemplates';
import { Designs } from '../designs/Designs';
import { useEffect, useState } from 'react';
import { resetCollectionState } from '@/redux/slices/collection';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { resetEditorState } from '@/redux/slices/editor';
import { ApiDashboard } from '../api-dashboard/ApiDashboard';
import { Integrations } from '../integrations/Integrations';
import { Shop } from '../shop/Shop';
import { Products } from '../products/Products';
import { Product } from '../product/Product';
import { CustomMockups } from '../custom-mockups/CustomMockups';
import { useMediaQuery } from 'react-responsive';
import { resetAiBackgroundsState } from '@/redux/slices/aiBackgrounds';
import { ColorSets } from '../color-sets/ColorSets';

interface IViewWrapper {
  activeSection: pageSlug;
}

export const ViewWrapper = ({ activeSection }: IViewWrapper) => {
  const dispatch = useAppDispatch();
  const { leftSideMenuWidth } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  useEffect(() => {
    dispatch(resetCollectionState());
    dispatch(resetEditorState());
    dispatch(resetAiBackgroundsState());
  }, []);

  return (
    <Box
      className="view-wrapper"
      style={{
        width: !isMobile
          ? `calc(100% - ${leftSideMenuWidth + 1 || '297'}px)`
          : 'auto',
      }}
    >
      {activeSection === 'custom-mockups' && <CustomMockups />}
      {activeSection === 'my-templates' && <MockupTemplates />}
      {activeSection === 'mockup-library' && <MockupCatalog />}
      {activeSection === 'account' && <WorkspaceSettings />}
      {activeSection === 'dashboard-api' && <ApiDashboard />}
      {activeSection === 'designs' && <Designs />}
      {activeSection === 'integrations' && <Integrations />}
      {activeSection === 'shop' && <Shop />}
      {activeSection === 'shop-products' && <Products />}
      {activeSection === 'product' && <Product />}
      {activeSection === 'colors' && <ColorSets />}
    </Box>
  );
};
