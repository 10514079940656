import { useAppSelector } from '@/hooks';
import { Flex, TextField, Text, Tooltip } from '@radix-ui/themes';
import { useState, useEffect, useCallback, useRef } from 'react';
import { IAssetFileConfig } from '@/features/screens/collection/components/editor/components/DesignAsset';

interface ISizePositionControls {
  assetFileConfig: IAssetFileConfig;
  updateAsset: (data: Partial<IAssetFileConfig>) => void;
}

const useDebouncedCallback = (func: any, wait: any) => {
  const timeout = useRef();

  return useCallback(
    (...args: any) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      (timeout as any).current = setTimeout(later, wait);
    },
    [func, wait]
  );
};

export const SizePositionControls = ({
  assetFileConfig,
  updateAsset,
}: ISizePositionControls) => {
  const { activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );

  const [dapWidth, setDapWidth] = useState<number>(0);
  const [dapHeight, setDapHeight] = useState<number>(0);
  const [dapLeft, setDapLeft] = useState<number>(0);
  const [dapTop, setDapTop] = useState<number>(0);

  useEffect(() => {
    if (activeSmartObject.print_area) {
      setDapWidth(
        Math.round(
          ((activeSmartObject.global_asset_width || 1) /
            activeSmartObject.width) *
            100
        )
      );
      setDapHeight(
        Math.round(
          ((activeSmartObject.global_asset_height || 1) /
            activeSmartObject.height) *
            100
        )
      );

      setDapLeft(
        Math.round(
          (1 -
            (activeSmartObject.width -
              ((activeSmartObject.global_asset_left || 1) +
                (activeSmartObject.global_asset_width || 1) / 2)) /
              activeSmartObject.width) *
            100
        )
      );
      setDapTop(
        Math.round(
          (1 -
            (activeSmartObject.height -
              ((activeSmartObject.global_asset_top || 1) +
                (activeSmartObject.global_asset_height || 1) / 2)) /
              activeSmartObject.height) *
            100
        )
      );
    } else {
      setDapWidth(
        Math.round(
          ((assetFileConfig.width || 1) / activeSmartObject.width) * 100
        )
      );
      setDapHeight(
        Math.round(
          ((assetFileConfig.height || 1) / activeSmartObject.height) * 100
        )
      );

      setDapLeft(
        Math.round(
          (1 -
            (activeSmartObject.width -
              ((assetFileConfig.transformX || 1) +
                (assetFileConfig.width || 1) / 2)) /
              activeSmartObject.width) *
            100
        )
      );
      setDapTop(
        Math.round(
          (1 -
            (activeSmartObject.height -
              ((assetFileConfig.transformY || 1) +
                (assetFileConfig.height || 1) / 2)) /
              activeSmartObject.height) *
            100
        )
      );
    }
  }, [activeSmartObject]);

  const onDapChange = useDebouncedCallback(
    (value: { width: number; height: number; left: number; top: number }) => {
      if (activeSmartObject.print_area) {
        if (value.width && value.height) {
          updateAsset({
            design_area_width: Math.round(
              (activeSmartObject.width || 1) * (value.width / 100)
            ),
            design_area_height: Math.round(
              (activeSmartObject.height || 1) * (value.height / 100)
            ),
            design_area_left: Math.round(
              (activeSmartObject.width || 1) * (dapLeft / 100) -
                (activeSmartObject.global_asset_width || 1) / 2
            ),
            design_area_top: Math.round(
              (activeSmartObject.height || 1) * (dapTop / 100) -
                (activeSmartObject.global_asset_height || 1) / 2
            ),
            rotate: assetFileConfig.rotate,
          });
        } else {
          updateAsset({
            design_area_width: Math.round(
              (activeSmartObject.width || 1) * (dapWidth / 100)
            ),
            design_area_height: Math.round(
              (activeSmartObject.height || 1) * (dapHeight / 100)
            ),
            design_area_left: Math.round(
              (activeSmartObject.width || 1) * (value.left / 100) -
                (activeSmartObject.global_asset_width || 1) / 2
            ),
            design_area_top: Math.round(
              (activeSmartObject.height || 1) * (value.top / 100) -
                (activeSmartObject.global_asset_height || 1) / 2
            ),
            rotate: assetFileConfig.rotate,
          });
        }
      } else {
        if (value.width && value.height) {
          updateAsset({
            width: Math.round(
              (activeSmartObject.width || 1) * (value.width / 100)
            ),
            height: Math.round(
              (activeSmartObject.height || 1) * (value.height / 100)
            ),
            transformX: Math.round(
              (activeSmartObject.width || 1) * (dapLeft / 100) -
                (assetFileConfig.width || 1) / 2
            ),
            transformY: Math.round(
              (activeSmartObject.height || 1) * (dapTop / 100) -
                (assetFileConfig.height || 1) / 2
            ),
            rotate: assetFileConfig.rotate,
          });
        } else {
          updateAsset({
            width: Math.round(
              (activeSmartObject.width || 1) * (dapWidth / 100)
            ),
            height: Math.round(
              (activeSmartObject.height || 1) * (dapHeight / 100)
            ),
            transformX: Math.round(
              (activeSmartObject.width || 1) * (value.left / 100) -
                (assetFileConfig.width || 1) / 2
            ),
            transformY: Math.round(
              (activeSmartObject.height || 1) * (value.top / 100) -
                (assetFileConfig.height || 1) / 2
            ),
            rotate: assetFileConfig.rotate,
          });
        }
      }
    },
    400
  );

  const handleDapWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setDapWidth(value);
    onDapChange({ width: value, height: dapHeight });
  };

  const handleDapHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setDapHeight(value);
    onDapChange({ width: dapWidth, height: value });
  };

  const handleDapLeftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setDapLeft(value);
    onDapChange({ left: value, top: dapTop });
  };

  const handleDapTopChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setDapTop(value);
    onDapChange({ left: dapLeft, top: value });
  };

  return (
    <Flex align={'center'} justify={'between'} py={'4'} gap={'4'}>
      <TextField.Root
        placeholder="0"
        type="number"
        value={dapWidth}
        onChange={handleDapWidthChange}
        style={{ textAlign: 'center' }}
      >
        <TextField.Slot>
          <Tooltip content="Design area">
            <Text weight={'medium'} size={'2'}>
              W
            </Text>
          </Tooltip>
        </TextField.Slot>
        <TextField.Slot>%</TextField.Slot>
      </TextField.Root>
      <TextField.Root
        placeholder="0"
        type="number"
        value={dapHeight}
        onChange={handleDapHeightChange}
      >
        <TextField.Slot>
          <Tooltip content="Design area height">
            <Text weight={'medium'} size={'2'}>
              H
            </Text>
          </Tooltip>
        </TextField.Slot>
        <TextField.Slot>%</TextField.Slot>
      </TextField.Root>
      <TextField.Root
        placeholder="0"
        type="number"
        value={dapLeft}
        onChange={handleDapLeftChange}
      >
        <TextField.Slot>
          <Tooltip content="Distance from left of the smart object">
            <Text weight={'medium'} size={'2'}>
              X
            </Text>
          </Tooltip>
        </TextField.Slot>
        <TextField.Slot>%</TextField.Slot>
      </TextField.Root>
      <TextField.Root
        placeholder="0"
        type="number"
        value={dapTop}
        onChange={handleDapTopChange}
      >
        <TextField.Slot>
          <Tooltip content="Distance from top of the smart object">
            <Text weight={'medium'} size={'2'}>
              Y
            </Text>
          </Tooltip>
        </TextField.Slot>
        <TextField.Slot>%</TextField.Slot>
      </TextField.Root>
    </Flex>
  );
};
