import { createSlice } from '@reduxjs/toolkit';
import { paymentsApi } from '@/services/payments';
import { UserRenders } from './types';

interface IPaymentsState {
  userRenders: UserRenders | null;
}

const initialState: IPaymentsState = {
  userRenders: null,
};

export const paymentsSlice = createSlice({
  initialState,
  name: 'paymentsSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      paymentsApi.endpoints.getUserRenders.matchFulfilled,
      (state, response) => {
        state.userRenders = response.payload.data[0];
      }
    );
  },
});

export default paymentsSlice.reducer;

export const {} = paymentsSlice.actions;
