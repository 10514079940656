import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { psdManagementApi } from '@/services/psdManagement';
import { PrivatePhotoshopFile } from '@/services/types';
import posthog from 'posthog-js';
import { useState, forwardRef, useImperativeHandle } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IDeletePhotoshopFile {
  privatePsd: PrivatePhotoshopFile;
}

export const DeletePhotoshopFile = forwardRef(
  ({ privatePsd }: IDeletePhotoshopFile, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);

    const [deletePrivatePsd] = psdManagementApi.useDeletePrivatePsdMutation();

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const deletePhotoshopFileAction = async (id: number) => {
      await deletePrivatePsd({ id });
      dispatch(
        psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
          forceRefetch: true,
        })
      );
      captureEvent('PSD Mockup deleted');
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Delete Photoshop file</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to delete this Photoshop file?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Cancel>
              <Button
                variant="solid"
                color="red"
                onClick={() => deletePhotoshopFileAction(privatePsd.psd_id)}
              >
                Delete
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
