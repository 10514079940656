import { ColorSet, colorsApi } from '@/services/colors';
import { createSlice } from '@reduxjs/toolkit';

interface IColorSetsState {
  colorSets: ColorSet[];
}

const initialState: IColorSetsState = {
  colorSets: null!,
};

export const colorsSlice = createSlice({
  initialState,
  name: 'colorsSlice',
  reducers: {
    setColorSets(state, { payload }) {
      state.colorSets = payload;
    },
    resetEditorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        colorsApi.endpoints.getColorSets.matchFulfilled,
        (state, response) => {
          state.colorSets = response.payload.data?.map((colorSet) => {
            return { ...colorSet };
          });
        }
      )
      .addMatcher(
        colorsApi.endpoints.renameColorSet.matchFulfilled,
        (state, response) => {
          const updatedColorSet = response.meta.arg.originalArgs;

          state.colorSets = state.colorSets.map((colorSet) =>
            colorSet.id === updatedColorSet.id
              ? { ...colorSet, ...updatedColorSet }
              : colorSet
          );
        }
      );
  },
});

export default colorsSlice.reducer;

export const { setColorSets } = colorsSlice.actions;
