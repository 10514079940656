import { createSlice } from '@reduxjs/toolkit';
import { shopsProductsApi } from '@/services/shopsProducts';
import {
  LastSync,
  Listing,
  ProductImage,
  ProductShortDetails,
} from '@/services/types';

interface IShopsProductsState {
  listings: {
    data: {
      products: Listing[];
      lastSync: LastSync;
    };
    isLoading: boolean;
  };
  productListing: {
    data: {
      details: ProductShortDetails;
      images: ProductImage[];
    };
    isLoading: boolean;
  };
  isSyncingListings: boolean;
}

const initialState: IShopsProductsState = {
  listings: {
    data: {
      products: [],
      lastSync: null!,
    },
    isLoading: false,
  },
  productListing: {
    data: {
      details: null!,
      images: [],
    },
    isLoading: false,
  },
  isSyncingListings: false,
};

export const shopsProductsSlice = createSlice({
  initialState,
  name: 'shopsProductsSlice',
  reducers: {
    resetListingsState(state) {
      state.listings.data = initialState.listings.data;
    },
    resetProductListingState(state) {
      state.productListing.data = initialState.productListing.data;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      shopsProductsApi.endpoints.getListings.matchPending,
      (state, response) => {
        state.listings.isLoading = true;
      }
    );

    builder.addMatcher(
      shopsProductsApi.endpoints.getListings.matchFulfilled,
      (state, response) => {
        state.listings.data.products = response.payload.data.listings || [];
        state.listings.data.lastSync = response.payload.data.last_sync || null;
        state.listings.isLoading = false;
      }
    );
    builder.addMatcher(
      shopsProductsApi.endpoints.getProductListing.matchPending,
      (state, response) => {
        state.productListing.isLoading = true;
      }
    );
    builder.addMatcher(
      shopsProductsApi.endpoints.getProductListing.matchFulfilled,
      (state, response) => {
        state.productListing.data.details = response.payload.data.listing;
        state.productListing.data.images = response.payload.data.images;
        state.productListing.isLoading = false;
      }
    );
    builder.addMatcher(
      shopsProductsApi.endpoints.syncListings.matchPending,
      (state, response) => {
        state.isSyncingListings = true;
      }
    );
    builder.addMatcher(
      shopsProductsApi.endpoints.syncListings.matchFulfilled,
      (state, response) => {
        state.isSyncingListings = false;
      }
    );
    builder.addMatcher(
      shopsProductsApi.endpoints.syncListings.matchRejected,
      (state, response) => {
        state.isSyncingListings = false;
      }
    );
  },
});

export default shopsProductsSlice.reducer;

export const { resetListingsState, resetProductListingState } =
  shopsProductsSlice.actions;
