import './onboarding.scss';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import {
  Text,
  Dialog,
  Flex,
  Button,
  Select,
  TextField,
  VisuallyHidden,
  CheckboxCards,
} from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right.svg';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAppSelector } from '@/hooks';
import posthog from 'posthog-js';

interface IFormInput {
  company_type: string;
  company_size: string;
  job_title: string;
  secondary_use_cases: string[];
}

type MainUseCase =
  | 'Bulk Mockup Creation'
  | 'Custom Integration'
  | 'Just for fun';

export const Onboarding = () => {
  const captureEvent = useCaptureEvent();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<number>(1);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const formRef = useRef(null);

  const onboardingCompleted = useFeatureFlagEnabled(
    'onboarding_form_completed'
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => submitOnboarding(data);

  const submitOnboarding = async (formInput: IFormInput) => {
    captureEvent('Onboarding - Form submitted');
    const data = {
      company_type: formInput.company_type,
      company_size: formInput.company_size,
      job_title: formInput.job_title,
      secondary_use_cases: formInput.secondary_use_cases.join(';'),
      onboarding_form_completed: true,
    };
    captureEvent('Onboarding - Step 2', { $set: data });
    setIsOpen(false);
  };

  const firstStepSubmit = (value: MainUseCase) => {
    captureEvent('Onboarding - Step 1', { $set: { main_use_case: value } });
    if (value === 'Just for fun') {
      captureEvent('Onboarding - Form submitted', {
        $set: {
          onboarding_form_completed: true,
        },
      });

      setIsOpen(false);
    } else {
      setStep(2);
    }
  };

  useEffect(() => {
    if (isOpen) {
      captureEvent('Onboarding modal opened');
    } else {
      captureEvent('Onboarding modal closed');
    }
  }, [isOpen]);

  useEffect(() => {
    async function onboardingCompletedAction() {
      if (
        currentWorkspace &&
        currentUser &&
        typeof onboardingCompleted !== 'undefined'
      ) {
        if (!onboardingCompleted) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      }
    }

    onboardingCompletedAction();
  }, [
    onboardingCompleted,
    posthog._isIdentified,
    currentWorkspace,
    currentUser,
  ]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
      <Dialog.Content
        className="onboarding-dialog"
        onPointerDownOutside={(e) => e.preventDefault()}
        aria-describedby={undefined}
        maxWidth={step === 1 ? '640px' : '480px'}
        style={{ minHeight: '464px' }}
      >
        <VisuallyHidden>
          <Dialog.Title></Dialog.Title>
        </VisuallyHidden>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {step === 1 && (
            <Flex
              className="onboarding-wrapper step-1"
              direction={'column'}
              gap="8"
              justify={'between'}
              height={'100%'}
            >
              <Flex direction={'column'} gap={'4'} align={'center'}>
                <Text
                  size={'6'}
                  weight={'bold'}
                  style={{ textAlign: 'center' }}
                >
                  Welcome to Dynamic Mockups
                  {currentUser?.first_name
                    ? `, ${currentUser?.first_name} 👋`
                    : ' 👋'}
                </Text>
                <Flex direction={'column'} gap={'1'} align={'center'}>
                  <Text
                    size={'3'}
                    color="gray"
                    weight={'regular'}
                    style={{ textAlign: 'center' }}
                  >
                    It’s great to have you here!
                  </Text>
                  <Text
                    size={'3'}
                    color="gray"
                    weight={'regular'}
                    style={{ textAlign: 'center' }}
                  >
                    Please let us know how will you use Dynamic Mockups.
                  </Text>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                gap="2"
                justify={'between'}
                height={'100%'}
                className="step-1-items"
              >
                <Flex
                  className="item"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                  gap="2"
                  p={'4'}
                  onClick={() => firstStepSubmit('Bulk Mockup Creation')}
                >
                  <Flex direction={'row'} align={'center'} gap="2">
                    <Flex
                      className="prefix-icon"
                      align={'center'}
                      justify={'center'}
                    >
                      🚀
                    </Flex>
                    <Flex direction={'column'} gap="1">
                      <Text size={'2'} weight={'bold'}>
                        Bulk Mockup Creation
                      </Text>
                      <Text size={'2'} color="gray" weight={'regular'}>
                        I'll use Dynamic Mockups to make lots of mockups at once
                      </Text>
                    </Flex>
                  </Flex>
                  <ArrowRightIcon className="icon" />
                </Flex>
                <Flex
                  className="item"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                  gap="2"
                  p={'4'}
                  onClick={() => firstStepSubmit('Custom Integration')}
                >
                  <Flex direction={'row'} align={'center'} gap="2">
                    <Flex
                      className="prefix-icon"
                      align={'center'}
                      justify={'center'}
                    >
                      🔌
                    </Flex>
                    <Flex direction={'column'} gap="1">
                      <Text size={'2'} weight={'bold'}>
                        Custom Integration
                      </Text>
                      <Text size={'2'} color="gray" weight={'regular'}>
                        I’ll connect Dynamic Mockups to my own software
                      </Text>
                    </Flex>
                  </Flex>
                  <ArrowRightIcon className="icon" />
                </Flex>
                <Flex
                  className="item"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                  gap="2"
                  p={'4'}
                  onClick={() => firstStepSubmit('Just for fun')}
                >
                  <Flex direction={'row'} align={'center'} gap="2">
                    <Flex
                      className="prefix-icon"
                      align={'center'}
                      justify={'center'}
                    >
                      🎨
                    </Flex>
                    <Flex direction={'column'} gap="1">
                      <Text size={'2'} weight={'bold'}>
                        Just for fun
                      </Text>
                      <Text size={'2'} color="gray" weight={'regular'}>
                        I’ll use Dynamic Mockups for personal projects
                      </Text>
                    </Flex>
                  </Flex>
                  <ArrowRightIcon className="icon" />
                </Flex>
              </Flex>
            </Flex>
          )}
          {step === 2 && (
            <Flex
              className="onboarding-wrapper step-2"
              direction={'column'}
              gap="8"
              justify={'between'}
              height={'100%'}
            >
              <Flex direction={'column'} gap={'4'} align={'center'}>
                <Text size={'6'} weight={'bold'}>
                  Customize your experience
                </Text>
              </Flex>
              <Flex
                direction={'column'}
                gap="2px"
                justify={'between'}
                height={'100%'}
                className="step-2-items"
              >
                <label>
                  <TextField.Slot>
                    <Text weight={'medium'} color="gray" size={'1'} mb={'1'}>
                      What type of company do you work for?
                    </Text>
                  </TextField.Slot>
                  <Controller
                    name="company_type"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Company type field is required',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select.Root value={value} onValueChange={onChange}>
                        <Select.Trigger
                          placeholder="Select company type"
                          style={{ width: '100%' }}
                        />
                        <Select.Content position="popper">
                          <Select.Item value="Startup">Startup</Select.Item>
                          <Select.Item value="Print-on-Demand Store">
                            Print-on-Demand Store
                          </Select.Item>
                          <Select.Item value="Print-on-Demand Marketplace">
                            Print-on-Demand Marketplace
                          </Select.Item>
                          <Select.Item value="Printing Company">
                            Printing Company
                          </Select.Item>
                          <Select.Item value="E-commerce Platform">
                            E-commerce Platform
                          </Select.Item>
                          <Select.Item value="Development Company">
                            Development Company
                          </Select.Item>
                          <Select.Item value="Marketing Agency">
                            Marketing Agency
                          </Select.Item>
                          <Select.Item value="Other">Other</Select.Item>
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </label>
                {errors.company_type && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.company_type.message}
                  </Text>
                )}
                <label>
                  <TextField.Slot>
                    <Text
                      weight={'medium'}
                      color="gray"
                      size={'1'}
                      mb={'1'}
                      mt={'4'}
                    >
                      What’s the size of your company?
                    </Text>
                  </TextField.Slot>
                  <Controller
                    name="company_size"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Company size field is required',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select.Root value={value} onValueChange={onChange}>
                        <Select.Trigger
                          placeholder="Select company size"
                          style={{ width: '100%' }}
                        />
                        <Select.Content position="popper">
                          <Select.Item value="1">Only me</Select.Item>
                          <Select.Item value="2-10">2-10</Select.Item>
                          <Select.Item value="11-50">11-50</Select.Item>
                          <Select.Item value="51-200">51-200</Select.Item>
                          <Select.Item value="201-500">201-500</Select.Item>
                          <Select.Item value="501-1000">501-1000</Select.Item>
                          <Select.Item value="1001+">1001+</Select.Item>
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </label>
                {errors.company_size && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.company_size.message}
                  </Text>
                )}
                <label>
                  <TextField.Slot>
                    <Text
                      weight={'medium'}
                      color="gray"
                      size={'1'}
                      mb={'1'}
                      mt={'4'}
                    >
                      What’s your role?
                    </Text>
                  </TextField.Slot>
                  <Controller
                    name="job_title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Job title field is required',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select.Root value={value} onValueChange={onChange}>
                        <Select.Trigger
                          placeholder="Select job title"
                          style={{ width: '100%' }}
                        />
                        <Select.Content position="popper">
                          <Select.Item value="Founder">Founder </Select.Item>
                          <Select.Item value="Executive">Executive</Select.Item>
                          <Select.Item value="Designer">Designer</Select.Item>
                          <Select.Item value="Engineer">Engineer</Select.Item>
                          <Select.Item value="Product Manager">
                            Product Manager
                          </Select.Item>
                          <Select.Item value="Partnership Manager">
                            Partnership Manager
                          </Select.Item>
                          <Select.Item value="Innovation Manager">
                            Innovation Manager
                          </Select.Item>
                          <Select.Item value="Sales">Sales</Select.Item>
                          <Select.Item value="Marketer">Marketer</Select.Item>
                          <Select.Item value="Other">Other</Select.Item>
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </label>
                {errors.job_title && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.job_title.message}
                  </Text>
                )}
                <label>
                  <TextField.Slot>
                    <Text
                      weight={'medium'}
                      color="gray"
                      size={'1'}
                      mb={'1'}
                      mt={'4'}
                    >
                      What will be your main use?
                    </Text>
                  </TextField.Slot>

                  <Controller
                    name="secondary_use_cases"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'At least one use case is required',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxCards.Root
                        value={value}
                        onValueChange={onChange}
                      >
                        <CheckboxCards.Item value="Photoshop Mockups">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              🖥️ Photoshop Mockups
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                        <CheckboxCards.Item value="PoD Product Personalization">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              👕 PoD Product Personalization
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                        <CheckboxCards.Item value="Low-code Automation">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              ⚡️ Low-code Automation
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                        <CheckboxCards.Item value="Product Image Testing">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              🧪 Product Image Testing
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                        <CheckboxCards.Item value="AI Design Automation">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              ✨ AI Design Automation
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                        <CheckboxCards.Item value="Marketing & Branding">
                          <Flex direction="column" width="100%">
                            <Text size={'2'} weight="medium">
                              📢 Marketing & Branding
                            </Text>
                          </Flex>
                        </CheckboxCards.Item>
                      </CheckboxCards.Root>
                    )}
                  />
                </label>
                {errors.secondary_use_cases && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.secondary_use_cases.message}
                  </Text>
                )}
              </Flex>
              <Flex justify={'end'}>
                <Button type="submit">Continue to App</Button>
              </Flex>
            </Flex>
          )}
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
