import './view-wrapper.scss';
import { Flex, Text, DropdownMenu, Checkbox } from '@radix-ui/themes';
import { MockupCollection, Project } from '@/services/types';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface ISingleTemplate {
  collection: MockupCollection;
  project: Project;
}

export const CollectionsAddRemove = ({
  collection,
  project,
}: ISingleTemplate) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { activeMockupCollectionId } = useAppSelector(
    (state) => state.mockupCollectionsReducer
  );

  const [isChecked, setIsChecked] = useState<boolean>(
    project.mockup_collections.some((col) => col.id === collection.id)
  );

  const addToMockupCollection = async (
    checked: boolean,
    mockupCollectionId: number
  ) => {
    const data = {
      mockup_id: project.id,
      mockup_collections: [mockupCollectionId],
      activeMockupCollectionId,
    };
    if (checked) {
      try {
        await dispatch(
          mockupCollectionsApi.endpoints.addToMockupCollection.initiate(data)
        ).unwrap();
        captureEvent('Mockup added to collection', {
          mockup_id: project.id,
          mockup_name: project.name,
          add_to_collection: mockupCollectionId,
        });
        toast.success('Template successfully added to collection.', {
          toastId: 'add_to_collection_success',
          position: 'bottom-right',
          autoClose: 5000,
        });
      } catch (error) {
        captureEvent('Failed to add mockup to collection', {
          mockup_id: project.id,
          mockup_name: project.name,
          add_to_collection: mockupCollectionId,
        });
        toast.error('Failed to add mockap to collection.', {
          toastId: 'add_to_collection_error',
          position: 'bottom-right',
          autoClose: 5000,
        });
      }
    } else {
      try {
        await dispatch(
          mockupCollectionsApi.endpoints.removeFromMockupCollection.initiate(
            data
          )
        ).unwrap();
        captureEvent('Mockup removed from collection', {
          mockup_id: project.id,
          mockup_name: project.name,
          remove_from_collection: mockupCollectionId,
        });
        toast.success('Template successfully removed from collection.', {
          toastId: 'remove_from_collection_success',
          position: 'bottom-right',
          autoClose: 5000,
        });
      } catch (error) {
        captureEvent('Failed to remove mockup from collection', {
          mockup_id: project.id,
          mockup_name: project.name,
          remove_from_collection: mockupCollectionId,
        });
        toast.error('Failed to remove mockap from collection.', {
          toastId: 'remove_from_collection_error',
          position: 'bottom-right',
          autoClose: 5000,
        });
      }
    }

    dispatch(
      mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
        forceRefetch: true,
      })
    );
  };

  return (
    <DropdownMenu.Item
      key={collection.id}
      onSelect={(event) => event.preventDefault()}
    >
      <Text as="label" size="2" style={{ cursor: 'pointer', width: '100%' }}>
        <Flex as="span" gap="2" width={'100%'}>
          <Checkbox
            onCheckedChange={(checked) => {
              setIsChecked(checked as boolean);
              addToMockupCollection(checked as boolean, collection.id);
            }}
            checked={isChecked}
          />{' '}
          {collection.name}
        </Flex>
      </Text>
    </DropdownMenu.Item>
  );
};
