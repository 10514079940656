import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import {
  ShopIntegrationPlatform,
  ActiveIntegrations,
  ActiveIntegrationDetails,
} from '@/services/types';
import { createSlice } from '@reduxjs/toolkit';

interface IShopsIntegrationsState {
  shopIntegrationPlatforms: ShopIntegrationPlatform[];
  activeIntegrations: ActiveIntegrations[];
  activeIntegrationDetails: ActiveIntegrationDetails;
}

const initialState: IShopsIntegrationsState = {
  shopIntegrationPlatforms: [],
  activeIntegrations: [],
  activeIntegrationDetails: null!,
};

export const shopsIntegrationsSlice = createSlice({
  initialState,
  name: 'shopsIntegrationsSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        shopsIntegrationsApi.endpoints.getShopIntegrationPlatforms
          .matchFulfilled,
        (state, response) => {
          state.shopIntegrationPlatforms = response.payload.data;
        }
      )
      .addMatcher(
        shopsIntegrationsApi.endpoints.getActiveIntegrations.matchFulfilled,
        (state, response) => {
          state.activeIntegrations = response.payload.data;
        }
      )
      .addMatcher(
        shopsIntegrationsApi.endpoints.getActiveIntegrationDetails
          .matchFulfilled,
        (state, response) => {
          state.activeIntegrationDetails = response.payload.data;
        }
      );
  },
});

export default shopsIntegrationsSlice.reducer;

export const {} = shopsIntegrationsSlice.actions;
