import { psdManagementApi } from '@/services/psdManagement';
import { PrivatePhotoshopFile, PsdCategory } from '@/services/types';
import { createSlice } from '@reduxjs/toolkit';

type CategorizedPhotoshopFiles = { [key: string]: PrivatePhotoshopFile[] };
interface IPsdManagementState {
  psdId: number;
  mockupId: number;
  photoshopFiles: PrivatePhotoshopFile[];
  categorizedPhotoshopFiles: CategorizedPhotoshopFiles;
  psdCategories: PsdCategory[];
  photoshopFilesCategories: number[];
}

const initialState: IPsdManagementState = {
  psdId: null!,
  mockupId: null!,
  photoshopFiles: [],
  categorizedPhotoshopFiles: {},
  psdCategories: [],
  photoshopFilesCategories: [],
};

export const psdManagementSlice = createSlice({
  initialState,
  name: 'psdManagementSlice',
  reducers: {
    setPsdId(state, { payload }) {
      state.psdId = payload;
    },
    setMockupId(state, { payload }) {
      state.mockupId = payload;
    },
    resetEditorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        psdManagementApi.endpoints.getPrivatePsds.matchFulfilled,
        (state, response) => {
          state.photoshopFiles = response.payload.data;

          const psdCategoryIds = response.payload.data.map(
            (project) => project.psd_category_id
          );
          state.photoshopFilesCategories = [
            ...new Set(psdCategoryIds as number[]),
          ];
        }
      )
      .addMatcher(
        psdManagementApi.endpoints.getPsdCategories.matchFulfilled,
        (state, response) => {
          state.psdCategories = response.payload.data;
        }
      );
  },
});

export default psdManagementSlice.reducer;

export const { setPsdId, setMockupId, resetEditorState } =
  psdManagementSlice.actions;
