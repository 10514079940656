import './auth.scss';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { authApi } from '@/services/auth';
import { ReactComponent as DesignCopilotLogo } from '@/assets/design-copilot-logo.svg';
import { Flex, Text, Heading } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IFormInput {
  email: string;
}

export const ForgotPasswordSuccess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const { forgotPasswordSuccessEmail } = useAppSelector(
    (state) => state.authReducer
  );

  const resendEmail = async () => {
    try {
      const dataInput = {
        email: forgotPasswordSuccessEmail,
      };
      captureEvent('Forgot password initiated', { email: dataInput });

      await dispatch(
        authApi.endpoints.forgotPassword.initiate(dataInput)
      ).unwrap();
      toast.success('Sent reset password request', {
        toastId: 'link_sent',
        position: 'bottom-right',
        autoClose: 7000,
      });
      captureEvent('Forgot password sent link', { email: dataInput });
    } catch (error: any) {
      console.log(error.data);

      toast.error(error.data.message, {
        toastId: 'forgot_password_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Dynamic Mockups | Forgot your password succcess</title>
        <link
          rel="canonical"
          href="https://app.dynamicmockups.com/forgot-password/success"
        />
      </Helmet>

      <Flex
        className="auth-page"
        align="center"
        justify="center"
        direction={'column'}
        style={{
          height: '100%',
        }}
        gap={'9'}
      >
        <Flex
          className="header"
          direction={'column'}
          align={'center'}
          gap={'6'}
        >
          <DesignCopilotLogo className="logo" />
          <Heading as={'h1'}>Check your email</Heading>
        </Flex>
        <Flex
          className="content"
          direction={'column'}
          justify={'center'}
          width={'500px'}
        >
          <Flex className="email-pass" direction={'column'} gap={'3'}>
            <Text size={'4'} color="gray" weight={'regular'} align={'center'}>
              We sent a password reset link to{' '}
              <b>{forgotPasswordSuccessEmail}</b>
            </Text>
            <Flex gap={'1'} align={'center'} justify={'center'}>
              <Text size={'1'} color="gray" weight={'regular'}>
                Didn't receive the email?
              </Text>
              <Text
                className="resend"
                onClick={() => resendEmail()}
                size={'1'}
                weight={'medium'}
              >
                Click to resend
              </Text>
            </Flex>
            <Text className="dont-have-acc" weight={'regular'} size={'1'}>
              Go back to{' '}
              <Text
                weight={'medium'}
                size={'1'}
                onClick={() => navigate(`/login`)}
              >
                Login
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex className="footer">
          <Text weight={'regular'} size={'1'}>
            By using Dynamic Mockups, you are agreeing to our{' '}
            <a
              href="https://dynamicmockups.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://dynamicmockups.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
