import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  DataModelArray,
  LastSync,
  DataModel,
  Listing,
  ProductImage,
  ProductListingRequest,
  ProductShortDetails,
} from './types';

export const shopsProductsApi = createApi({
  reducerPath: 'shopsProductsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getListings: builder.query<
      DataModel<{ listings: Listing[]; last_sync: LastSync }>,
      { shop_integration_platform_id: number }
    >({
      query: ({ shop_integration_platform_id }) =>
        `shop-integration/listings?shop_integration_platform_id=${shop_integration_platform_id}`,
    }),
    getProductListing: builder.query<
      DataModel<{ listing: ProductShortDetails; images: ProductImage[] }>,
      ProductListingRequest
    >({
      query: ({ listing_id, shop_integration_platform_id }) =>
        `shop-integration/listing/images?shop_integration_platform_id=${shop_integration_platform_id}&listing_id=${listing_id}`,
      transformResponse: (
        response: DataModel<{
          listing: ProductShortDetails;
          images: ProductImage[];
        }>
      ) => {
        const updatedData = {
          listing: response.data.listing,
          images: response.data.images?.map((product) => ({
            ...product,
            is_updated: false,
          })),
        };

        return {
          data: updatedData,
          message: '',
          success: true,
        };
      },
    }),
    syncImages: builder.query<
      void,
      {
        shop_integration_platform_id: number;
        listing_id: number;
        productImages: ProductImage[];
      }
    >({
      query: ({ productImages, shop_integration_platform_id, listing_id }) => ({
        url: `shop-integration/listing/images/sync`,
        method: 'POST',
        body: {
          shop_integration_platform_id,
          listing_id,
          images: productImages,
        },
      }),
    }),
    syncListings: builder.mutation<
      void,
      { shop_integration_platform_id: number }
    >({
      query: ({ shop_integration_platform_id }) => ({
        url: `shop-integration/listings/sync`,
        method: 'POST',
        body: { shop_integration_platform_id },
      }),
    }),
  }),
});

export const {} = shopsProductsApi;
