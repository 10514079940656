import throttle from 'lodash/throttle.js';
import { Link, Strong } from '@radix-ui/themes';
import Uppy, { FileProgress, UppyFile } from '@uppy/core';

interface CalculateProgress {
  uppyDashboard: Uppy<Record<string, unknown>, Record<string, unknown>>;
  file: UppyFile<Record<string, unknown>, Record<string, unknown>> | undefined;
  data: FileProgress;
  setFileUploadProgress: (progress: number) => void;
  setUploaderInfo?: (data: {
    numberOfFiles: number;
    totalSize: number;
  }) => void;
}

interface CalculateTotalProgress {
  uppyDashboard: Uppy<Record<string, unknown>, Record<string, unknown>>;
  setFileUploadProgress: (progress: number) => void;
  setUploaderInfo?: (data: {
    numberOfFiles: number;
    totalSize: number;
  }) => void;
}

export const getProgressColor = (psdStatus: string) => {
  switch (psdStatus) {
    case 'completed':
      return 'green';
    case 'uploading':
      return 'blue';
    case 'processing':
      return 'blue';
    case 'upload-error':
      return 'red';
    case 'processing-error':
      return 'red';
    default:
      return 'red';
  }
};

export const getProgressText = (psdStatus: string, psdErrorMessage: string) => {
  let psdEngineError: { detail: string; error_code: string } = {
    detail: '',
    error_code: '',
  };
  if (psdErrorMessage) {
    try {
      psdEngineError = JSON.parse(psdErrorMessage);
    } catch (error) {
      return (
        <>
          Your PSD file doesn't comply with{' '}
          <Link
            href="https://dynamicmockups.com/knowledge/photoshop-psd-format/"
            target="_blank"
          >
            our guidelines
          </Link>
          . Please, modify your layer and mask structure like in{' '}
          <Link
            href="https://app-dynamicmockups-production.s3.eu-central-1.amazonaws.com/public/Dynamic+Mockups+Photoshop+Template+Example.psd"
            download="Dynamic Mockups Photoshop Template Example.psd"
            target="_blank"
          >
            our example
          </Link>{' '}
          or contact us at <Strong>support@dynamicmockups.com</Strong> for help.
        </>
      );
    }
  }

  switch (psdStatus) {
    case 'completed':
      return 'Completed';
    case 'uploading':
      return 'Uploading...';
    case 'processing':
      return 'Processing...';
    case 'upload-error':
      return `Error: ${psdErrorMessage}`;
    case 'processing-error':
      if (psdEngineError?.error_code) {
        return psdEngineError?.detail;
      } else {
        return (
          <>
            Your PSD file doesn't comply with{' '}
            <Link
              href="https://dynamicmockups.com/knowledge/photoshop-psd-format/"
              target="_blank"
            >
              our guidelines
            </Link>
            . Please, modify your layer and mask structure like in{' '}
            <Link
              href="https://app-dynamicmockups-production.s3.eu-central-1.amazonaws.com/public/Dynamic+Mockups+Photoshop+Template+Example.psd"
              download="Dynamic Mockups Photoshop Template Example.psd"
              target="_blank"
            >
              our example
            </Link>{' '}
            or contact us at <Strong>support@dynamicmockups.com</Strong> for
            help.
          </>
        );
      }

    default:
      return 'red';
  }
};

export const calculateProgress = throttle(
  ({
    uppyDashboard,
    file,
    data,
    setFileUploadProgress,
    setUploaderInfo,
  }: CalculateProgress) => {
    const fileInState = uppyDashboard.getFile(file?.id as string);
    if (file == null || !fileInState) {
      uppyDashboard.log(
        `Not setting progress for a file that has been removed: ${file?.id}`
      );
      return;
    }

    if (fileInState?.progress?.percentage === 100) {
      uppyDashboard.log(
        `Not setting progress for a file that has been already uploaded: ${file.id}`
      );
      return;
    }

    // bytesTotal may be null or zero; in that case we can't divide by it
    const canHavePercentage =
      Number.isFinite(data.bytesTotal) && data.bytesTotal > 0;
    uppyDashboard.setFileState(file.id, {
      progress: {
        ...fileInState.progress,
        bytesUploaded: data.bytesUploaded,
        bytesTotal: data.bytesTotal,
        percentage: canHavePercentage
          ? Math.round((data.bytesUploaded / data.bytesTotal) * 100)
          : 0,
      },
    });

    calculateTotalProgress({
      uppyDashboard,
      setFileUploadProgress,
      setUploaderInfo,
    });
  },
  500,
  { leading: true, trailing: true }
);

export const calculateTotalProgress = ({
  uppyDashboard,
  setFileUploadProgress,
  setUploaderInfo,
}: CalculateTotalProgress) => {
  // calculate total progress, using the number of files currently uploading,
  // multiplied by 100 and the summ of individual progress of each file
  const files = uppyDashboard.getFiles();

  const inProgress = files.filter((file) => {
    return (
      file?.progress?.uploadStarted ||
      (file?.progress as any)?.preprocess ||
      (file?.progress as any)?.postprocess
    );
  });

  if (inProgress.length === 0) {
    uppyDashboard.emit('progress', 0);
    uppyDashboard.setState({ totalProgress: 0 });
    return;
  }

  const sizedFiles = inProgress.filter(
    (file) => file?.progress?.bytesTotal != null
  );
  const unsizedFiles = inProgress.filter(
    (file) => file?.progress?.bytesTotal == null
  );

  if (sizedFiles.length === 0) {
    const progressMax = inProgress.length * 100;
    const currentProgress = unsizedFiles.reduce((acc, file) => {
      return acc + (file?.progress as any)?.percentage;
    }, 0);
    const totalProgress = Math.round((currentProgress / progressMax) * 100);
    uppyDashboard.setState({ totalProgress });
    return;
  }

  let totalSize = sizedFiles.reduce((acc, file) => {
    return acc + (file?.progress as any)?.bytesTotal;
  }, 0);
  const averageSize = totalSize / sizedFiles.length;
  totalSize += averageSize * unsizedFiles.length;

  let uploadedSize = 0;
  sizedFiles.forEach((file) => {
    uploadedSize += (file?.progress as any)?.bytesUploaded;
  });
  unsizedFiles.forEach((file) => {
    uploadedSize += (averageSize * (file?.progress?.percentage || 0)) / 100;
  });

  let totalProgress =
    totalSize === 0 ? 0 : Math.round((uploadedSize / totalSize) * 100);

  // hot fix, because:
  // uploadedSize ended up larger than totalSize, resulting in 1325% total
  if (totalProgress > 100) {
    totalProgress = 100;
  }

  if (setUploaderInfo) {
    setUploaderInfo({
      numberOfFiles: files.length,
      totalSize: Math.round((totalSize / 1024 / 1024) * 10) / 10,
    });
  }

  setFileUploadProgress(totalProgress);
};
