import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/hooks';
import { Project } from '@/services/types';
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { workspaceApi } from '@/services/workspace';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ColorSet, colorsApi } from '@/services/colors';

interface IFormInput {
  colorSetName: string;
}

interface IEditColorSet {
  colorSet: ColorSet;
}

export const EditColorSet = forwardRef(({ colorSet }: IEditColorSet, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const [open, setOpen] = useState<boolean>(false);
  const [updateColorSetIsLoading, setUpdateColorSetIsLoading] = useState(false);
  const [colorSetName, setColorSetName] = useState<string>(colorSet.name);

  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updateMockupAction(data);

  const updateMockupAction = async (formInput: IFormInput) => {
    try {
      setUpdateColorSetIsLoading(true);
      const data = {
        id: colorSet.id,
        name: colorSetName,
      };
      await dispatch(
        colorsApi.endpoints.renameColorSet.initiate(data)
      ).unwrap();

      captureEvent('Color Set renamed');

      toast.success('Color Set successfully updated.', {
        toastId: 'update_mockup_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'update_color_set_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } finally {
      setUpdateColorSetIsLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
        <Dialog.Title>
          <Flex direction={'row'} align={'center'} justify={'between'}>
            <Text>Edit Color Set</Text>
          </Flex>
        </Dialog.Title>
        <Flex direction={'column'}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={formRef}
          >
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'2'} mb={'1'}>
                  Color Set Name
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="Color Set Name"
                value={colorSetName}
                onChange={(e) => setColorSetName(e.currentTarget.value)}
              />
            </label>
            {errors.colorSetName && (
              <Text role="alert" size={'1'} color="red">
                {errors.colorSetName.message}
              </Text>
            )}
            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button type="submit" loading={updateColorSetIsLoading}>
                  Save Changes
                </Button>
              </Dialog.Close>
            </Flex>
          </form>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
});
