import './auth.scss';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from '@/hooks';
import { authApi } from '@/services/auth';
import { ReactComponent as GoogleIcon } from '@/assets/google.svg';
import { ReactComponent as DesignCopilotLogo } from '@/assets/design-copilot-logo.svg';
import {
  Flex,
  Text,
  IconButton,
  TextField,
  Button,
  Heading,
} from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IFormInput {
  email: string;
  password: string;
}

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (window.location.search && window.location.search.includes('code=')) {
      const fetchData = async () => {
        try {
          await dispatch(
            authApi.endpoints.googleAuthCallback.initiate(
              window.location.search
            )
          ).unwrap();
        } catch (error) {
          setShowContent(true);
        }
      };

      fetchData().catch(console.error);
    } else {
      setShowContent(true);
    }
  }, []);

  const googleAuth = async () => {
    try {
      const url = await dispatch(
        authApi.endpoints.googleAuth.initiate()
      ).unwrap();

      window.location.replace(url.data);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => loginSubmit(data);

  const loginSubmit = async (data: IFormInput) => {
    try {
      const inputs = {
        email: data.email,
        password: data.password,
      };
      await dispatch(authApi.endpoints.login.initiate(inputs)).unwrap();
    } catch (error: any) {
      console.log(error.data);

      toast.error(error.data.message, {
        toastId: 'login_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Dynamic Mockups | Login</title>
        <link rel="canonical" href="https://app.dynamicmockups.com/login/" />
      </Helmet>
      {showContent && (
        <Flex
          className="auth-page"
          align="center"
          justify="center"
          direction={'column'}
          style={{
            height: '100%',
          }}
        >
          <Flex
            className="header"
            direction={'column'}
            align={'center'}
            gap={'2'}
          >
            <DesignCopilotLogo className="logo" />
            <Heading as={'h1'}>Login to Dynamic Mockups</Heading>
          </Flex>
          <Flex className="content" direction={'column'} justify={'center'}>
            <Flex className="google" direction={'column'} align={'center'}>
              <IconButton
                title="Continue with google"
                className="google-btn"
                color="gray"
                variant="outline"
                onClick={() => googleAuth()}
              >
                <GoogleIcon />
                <Text size="3" weight="medium" ml={'2'}>
                  Continue with Google
                </Text>
              </IconButton>
              <Text my="5" weight={'regular'} size={'1'}>
                or log in with email
              </Text>
            </Flex>
            <Flex className="email-pass" direction={'column'}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '400px',
                }}
              >
                <TextField.Root
                  placeholder="Email Address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Email field is required',
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match Email format',
                    },
                  })}
                />

                {errors.email && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.email.message}
                  </Text>
                )}
                <TextField.Root
                  mt="2"
                  placeholder="Password"
                  type="password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Password field is required',
                    },
                  })}
                />
                {errors.password && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.password.message}
                  </Text>
                )}
                <Text
                  className="forgot-password"
                  weight={'regular'}
                  size={'1'}
                  mt={'2'}
                  onClick={() => navigate(`/forgot-password`)}
                >
                  Forgot your Password?
                </Text>
                <Button mt={'5'} className="login-btn" type="submit">
                  Log in to your account
                </Button>
              </form>

              <Text className="dont-have-acc" weight={'regular'} size={'1'}>
                Don’t have an account?{' '}
                <Text
                  weight={'medium'}
                  size={'1'}
                  onClick={() => navigate(`/register`)}
                >
                  Sign Up
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Flex className="footer">
            <Text weight={'regular'} size={'1'}>
              By using Dynamic Mockups, you are agreeing to our{' '}
              <a
                href="https://dynamicmockups.com/terms-of-service/"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://dynamicmockups.com/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};
