import { useAppDispatch } from '@/hooks';
import './view-wrapper.scss';
import {
  Text,
  ScrollArea,
  Flex,
  Button,
  Card,
  TextField,
} from '@radix-ui/themes';
import { useAppSelector } from '@/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userApi } from '@/services/user';
import { useEffect, useState } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import posthog from 'posthog-js';
import { logout } from '@/redux/slices/auth';
import { ToggleThemeButton } from '@/features/layout/header/components/ToggleThemeModeButton';

interface IUserAccountDetails {
  firstName: string;
  lastName: string;
}

export const ViewWrapper = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const captureEvent = useCaptureEvent();

  const appLogout = () => {
    captureEvent('User Logged Out');
    // reset posthog after logout to unlink any future events made on that device with that user
    posthog.reset();

    dispatch(logout());
    window.location.replace('/login');
  };
  const [isLoadingUserDetails, setIsLoadingUserDetails] =
    useState<boolean>(false);

  useEffect(() => {
    setValue('firstName', currentUser?.first_name || '');
    setValue('lastName', currentUser?.last_name || '');
  }, [currentUser]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty },
    setValue,
    getValues,
    reset,
  } = useForm<IUserAccountDetails>({
    defaultValues: {
      firstName: currentUser?.first_name,
      lastName: currentUser?.last_name,
    },
  });
  const onUserAccountDetailsSubmit: SubmitHandler<IUserAccountDetails> = (
    data
  ) => UserAccountDetailsSubmit(data);

  const UserAccountDetailsSubmit = async (data: IUserAccountDetails) => {
    if (
      getValues('firstName') === currentUser?.first_name &&
      getValues('lastName') === currentUser?.last_name
    ) {
      return;
    }
    try {
      setIsLoadingUserDetails(true);
      const inputs = {
        first_name: data.firstName,
        last_name: data.lastName,
      };
      await dispatch(
        userApi.endpoints.updateUserDetails.initiate(inputs)
      ).unwrap();
      await dispatch(
        userApi.endpoints.getCurrentUser.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
      captureEvent('User account details updated', {
        $set: {
          email: currentUser?.email,
          first_name: data.firstName,
          last_name: data.lastName,
        },
      });
      toast.success('User account details updated', {
        toastId: 'user_details_updated',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } catch (error: any) {
      console.log(error.data);

      toast.error(error.data.message, {
        toastId: 'profile_details_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } finally {
      setIsLoadingUserDetails(false);
    }
  };

  const [canSaveChanges, setCanSaveChanges] = useState<boolean>(false);

  useEffect(() => {
    if (isFormDirty) {
      setCanSaveChanges(true);
    } else {
      setCanSaveChanges(false);
    }
  }, [isFormDirty]);

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{ height: 'calc(100vh - 133px)' }}
    >
      <Flex
        className="view-wrapper"
        direction={'column'}
        gap={'3'}
        px={'5'}
        maxWidth={'500px'}
      >
        <Card className="card-wrapper">
          <Text size={'4'} weight={'medium'}>
            User Account Details
          </Text>
          <Flex direction={'column'} gap={'8px'}>
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginTop: '12px',
              }}
            >
              <Flex direction={'column'} gap={'0'}>
                <label>
                  <Text size={'2'}>First Name</Text>
                  <TextField.Root
                    placeholder="John"
                    {...register('firstName', {
                      required: {
                        value: true,
                        message: 'First Name field is required',
                      },
                    })}
                  />
                </label>
                {errors.firstName && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.firstName.message}
                  </Text>
                )}
              </Flex>
              <Flex direction={'column'} gap={'0'}>
                <label>
                  <Text size={'2'}>Last Name</Text>
                  <TextField.Root
                    placeholder="Doe"
                    {...register('lastName', {
                      required: {
                        value: true,
                        message: 'Last Name field is required',
                      },
                    })}
                  />
                </label>
                {errors.lastName && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.lastName.message}
                  </Text>
                )}
              </Flex>
              <label>
                <Text size={'2'}>Email</Text>
                <TextField.Root
                  mb={'2'}
                  defaultValue={currentUser?.email}
                  disabled
                />
              </label>
            </form>
            <label>
              <Text size={'2'}>Password</Text>
              <TextField.Root type="password" defaultValue="********" disabled>
                <TextField.Slot
                  style={{ width: '7px', padding: '0' }}
                ></TextField.Slot>
                <TextField.Slot>
                  <ChangePasswordDialog />
                </TextField.Slot>
              </TextField.Root>
            </label>
            {canSaveChanges && (
              <Button
                mt={'2'}
                className="login-btn"
                type="button"
                loading={isLoadingUserDetails}
                style={{ maxWidth: '200px' }}
                onClick={handleSubmit(onUserAccountDetailsSubmit)}
              >
                Save changes
              </Button>
            )}
          </Flex>
        </Card>
        <Card className="card-wrapper">
          <Text size={'4'} weight={'medium'}>
            Preferences
          </Text>
          <Flex direction={'column'} gap={'8px'} mt={'3'}>
            <Flex align={'center'} gap={'3'}>
              <Text weight={'regular'} size={'2'}>
                Toggle theme:
              </Text>{' '}
              <ToggleThemeButton />
            </Flex>
            <Button
              variant="outline"
              color="gray"
              style={{ width: '100px' }}
              onClick={() => appLogout()}
            >
              Logout
            </Button>
          </Flex>
        </Card>
      </Flex>
    </ScrollArea>
  );
};
