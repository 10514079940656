import './auth.scss';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from '@/hooks';
import { authApi } from '@/services/auth';
import { ReactComponent as GoogleIcon } from '@/assets/google.svg';
import { ReactComponent as DesignCopilotLogo } from '@/assets/design-copilot-logo.svg';
import {
  Flex,
  Text,
  IconButton,
  TextField,
  Button,
  Heading,
} from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { watch } from 'fs';
import {
  CheckCircledIcon,
  EyeClosedIcon,
  EyeOpenIcon,
} from '@radix-ui/react-icons';

interface IFormInput {
  email: string;
  password: string;
}

export const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (window.location.search && window.location.search.includes('code=')) {
      const fetchData = async () => {
        try {
          await dispatch(
            authApi.endpoints.googleAuthCallback.initiate(
              window.location.search
            )
          ).unwrap();
        } catch (error) {
          setShowContent(true);
        }
      };

      fetchData().catch(console.error);
    } else {
      setShowContent(true);
    }
  }, []);

  const googleAuth = async () => {
    try {
      const url = await dispatch(
        authApi.endpoints.googleAuth.initiate()
      ).unwrap();

      window.location.replace(url.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [lowercaseUppercaseError, setLowercaseUppercaseError] =
    useState<boolean>(false);
  const [minCharsError, setMinCharsError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [specialCharError, setSpecialCharError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => registerSubmit(data);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const registerSubmit = async (data: IFormInput) => {
    try {
      let checkLowercaseUppercase = false;
      let checkminChars = false;
      let checknumber = false;
      let checkspecialChar = false;
      if (!checkPassword('lowercaseUppercase')) {
        setLowercaseUppercaseError(true);
        checkLowercaseUppercase = true;
      }
      if (!checkPassword('minChars')) {
        setMinCharsError(true);
        checkminChars = true;
      }
      if (!checkPassword('number')) {
        setNumberError(true);
        checknumber = true;
      }
      if (!checkPassword('specialChar')) {
        setSpecialCharError(true);
        checkspecialChar = true;
      }

      if (
        checkLowercaseUppercase ||
        checkminChars ||
        checknumber ||
        checkspecialChar
      ) {
        return;
      }
      setIsLoading(true);

      const inputs = {
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
      };

      await dispatch(authApi.endpoints.register.initiate(inputs)).unwrap();
    } catch (error: any) {
      console.log(error.data);

      parseAndRenderErrors(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const parseAndRenderErrors = (response: any) => {
    const errors = response.errors;

    Object.keys(errors).forEach((field, index) => {
      const errorMessages = errors[field].join('. ');
      toast.error(errorMessages, {
        toastId: `error_${field}_${index}`,
        position: 'bottom-right',
        autoClose: 7000,
      });
    });
  };

  const password = useRef({});
  password.current = watch('password', '');

  useEffect(() => {
    if (password.current) {
      setLowercaseUppercaseError(false);
      setMinCharsError(false);
      setNumberError(false);
      setSpecialCharError(false);
    }
  }, [password.current]);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const checkPassword = (
    rule: 'minChars' | 'lowercaseUppercase' | 'number' | 'specialChar'
  ) => {
    switch (rule) {
      case 'minChars':
        return /.{8,}/.test(password.current as string);
      case 'lowercaseUppercase':
        return /(?=.*[a-z])(?=.*[A-Z])/.test(password.current as string);
      case 'number':
        return /(?=.*\d)/.test(password.current as string);
      case 'specialChar':
        return /(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password.current as string);
      default:
        return false;
    }
  };

  return (
    <>
      <Helmet>
        <title>Dynamic Mockups | Register</title>
        <link rel="canonical" href="https://app.dynamicmockups.com/register/" />
      </Helmet>
      {showContent && (
        <Flex
          className="auth-page"
          align="center"
          justify="center"
          direction={'column'}
          style={{
            height: '100%',
          }}
        >
          <Flex
            className="header"
            direction={'column'}
            align={'center'}
            gap={'2'}
          >
            <DesignCopilotLogo className="logo" />

            <Heading as={'h1'}> Register to Dynamic Mockups</Heading>
          </Flex>
          <Flex className="content" direction={'column'} justify={'center'}>
            <Flex className="google" direction={'column'} align={'center'}>
              <IconButton
                title="Sign Up with Google"
                className="google-btn"
                color="gray"
                variant="outline"
                onClick={() => googleAuth()}
              >
                <GoogleIcon />
                <Text size="3" weight="medium" ml={'2'}>
                  Sign Up with Google
                </Text>
              </IconButton>
              <Text my="5" weight={'regular'} size={'1'}>
                or sign up with email
              </Text>
            </Flex>
            <Flex className="email-pass" direction={'column'}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '400px',
                }}
              >
                <TextField.Root
                  placeholder="Email Address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Email field is required',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Maximum Email length is 255 characters',
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match Email format',
                    },
                  })}
                />
                {errors.email && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.email.message}
                  </Text>
                )}
                <TextField.Root
                  mt="2"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Password field is required',
                    },
                  })}
                >
                  <TextField.Slot style={{ display: 'none' }}></TextField.Slot>
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOpenIcon height="14" width="14" />
                      ) : (
                        <EyeClosedIcon height="14" width="14" />
                      )}
                    </IconButton>
                  </TextField.Slot>
                </TextField.Root>
                {errors.password && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.password.message}
                  </Text>
                )}
                <Flex direction={'column'} gap={'4px'} mt={'8px'}>
                  <Flex gap={'4px'} align={'center'}>
                    <CheckCircledIcon
                      color={
                        lowercaseUppercaseError
                          ? 'red'
                          : checkPassword('lowercaseUppercase')
                          ? 'green'
                          : 'gray'
                      }
                    />
                    <Text
                      role="alert"
                      size={'1'}
                      color={
                        lowercaseUppercaseError
                          ? 'red'
                          : checkPassword('lowercaseUppercase')
                          ? 'green'
                          : 'gray'
                      }
                    >
                      Password must contain 1 upper case and 1 lowecase letter
                    </Text>
                  </Flex>
                  <Flex gap={'4px'} align={'center'}>
                    <CheckCircledIcon
                      color={
                        numberError
                          ? 'red'
                          : checkPassword('number')
                          ? 'green'
                          : 'gray'
                      }
                    />
                    <Text
                      role="alert"
                      size={'1'}
                      color={
                        numberError
                          ? 'red'
                          : checkPassword('number')
                          ? 'green'
                          : 'gray'
                      }
                    >
                      Password must contain 1 number
                    </Text>
                  </Flex>
                  <Flex gap={'4px'} align={'center'}>
                    <CheckCircledIcon
                      color={
                        specialCharError
                          ? 'red'
                          : checkPassword('specialChar')
                          ? 'green'
                          : 'gray'
                      }
                    />
                    <Text
                      role="alert"
                      size={'1'}
                      color={
                        specialCharError
                          ? 'red'
                          : checkPassword('specialChar')
                          ? 'green'
                          : 'gray'
                      }
                    >
                      Password must contain 1 special character
                    </Text>
                  </Flex>
                  <Flex gap={'4px'} align={'center'}>
                    <CheckCircledIcon
                      color={
                        minCharsError
                          ? 'red'
                          : checkPassword('minChars')
                          ? 'green'
                          : 'gray'
                      }
                    />
                    <Text
                      role="alert"
                      size={'1'}
                      color={
                        minCharsError
                          ? 'red'
                          : checkPassword('minChars')
                          ? 'green'
                          : 'gray'
                      }
                    >
                      Password must be over 8 characters
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  mt={'5'}
                  className="login-btn"
                  type="submit"
                  loading={isLoading}
                >
                  Create new account
                </Button>
              </form>
              <Text className="dont-have-acc" weight={'regular'} size={'1'}>
                Already have an account?{' '}
                <Text
                  weight={'medium'}
                  size={'1'}
                  onClick={() => navigate(`/login`)}
                >
                  Log In
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Flex className="footer">
            <Text weight={'regular'} size={'1'}>
              By using Dynamic Mockups, you are agreeing to our{' '}
              <a
                href="https://dynamicmockups.com/terms-of-service/"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://dynamicmockups.com/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};
