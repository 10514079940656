import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import authReducer from './slices/auth';
import appReducer from './slices/app';
import collectionReducer from './slices/collection';
import editorReducer from './slices/editor';
import userReducer from './slices/user';
import workspaceReducer from './slices/workspace';
import shopsIntegrations from './slices/shopsIntegrations';
import paymentsReducer from './slices/payments';
import psdManagementReducer from './slices/psdManagement';
import designsReducer from './slices/designs';
import shopsProductsReducer from './slices/shopsProducts';
import mockupCollectionsReducer from './slices/mockupCollections';
import aiBackgroundsReducer from './slices/aiBackgrounds';
import colorSetsReducer from './slices/colors';

import { authApi } from '../services/auth';
import { psdEngineApi } from '@/services/psdEngine';
import { workspaceApi } from '@/services/workspace';
import { userApi } from '@/services/user';
import { apiKeyApi } from '@/services/apiKey';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import { paymentsApi } from '@/services/payments';
import { psdManagementApi } from '@/services/psdManagement';
import { designsApi } from '@/services/designs';
import { shopsProductsApi } from '@/services/shopsProducts';
import { aiBackgroundsApi } from '@/services/aiBackgrounds';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { colorsApi } from '@/services/colors';

const combinedReducer = combineReducers({
  authReducer,
  appReducer,
  collectionReducer,
  editorReducer,
  userReducer,
  workspaceReducer,
  shopsIntegrations,
  paymentsReducer,
  psdManagementReducer,
  designsReducer,
  shopsProductsReducer,
  mockupCollectionsReducer,
  aiBackgroundsReducer,
  colorSetsReducer,

  [authApi.reducerPath]: authApi.reducer,
  [psdEngineApi.reducerPath]: psdEngineApi.reducer,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [apiKeyApi.reducerPath]: apiKeyApi.reducer,
  [shopsIntegrationsApi.reducerPath]: shopsIntegrationsApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [psdManagementApi.reducerPath]: psdManagementApi.reducer,
  [designsApi.reducerPath]: designsApi.reducer,
  [shopsProductsApi.reducerPath]: shopsProductsApi.reducer,
  [aiBackgroundsApi.reducerPath]: aiBackgroundsApi.reducer,
  [mockupCollectionsApi.reducerPath]: mockupCollectionsApi.reducer,
  [colorsApi.reducerPath]: colorsApi.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const store = configureStore({
  reducer: combinedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      psdEngineApi.middleware,
      workspaceApi.middleware,
      userApi.middleware,
      apiKeyApi.middleware,
      shopsIntegrationsApi.middleware,
      paymentsApi.middleware,
      psdManagementApi.middleware,
      designsApi.middleware,
      shopsProductsApi.middleware,
      aiBackgroundsApi.middleware,
      mockupCollectionsApi.middleware,
      colorsApi.middleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
