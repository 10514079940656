import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { MockupCollection } from '@/services/types';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { toast } from 'react-toastify';
import { setActiveMockupCollectionId } from '@/redux/slices/mockupCollections';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IDeleteCollection {}

export const DeleteCollection = forwardRef(({}: IDeleteCollection, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const [open, setOpen] = useState<boolean>(false);
  const [collectionId, setCollectionId] = useState<number>(0);
  const [collectionName, setCollectionName] = useState<string>('');
  const { activeMockupCollectionId } = useAppSelector(
    (state) => state.mockupCollectionsReducer
  );
  const [deleteMockupCollection] =
    mockupCollectionsApi.useDeleteMockupCollectionMutation();

  useImperativeHandle(ref, () => ({
    openDialog(collection: MockupCollection) {
      setCollectionId(collection.id);
      setCollectionName(collection.name);
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const deleteCollectionAction = async (id: number) => {
    await deleteMockupCollection({ id });
    captureEvent('Collection deleted', { id, collectionName });
    const getMockupCollections = await dispatch(
      mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
        forceRefetch: true,
      })
    );

    if (
      !getMockupCollections.data?.data.find(
        (mc) => mc.id === activeMockupCollectionId
      )
    ) {
      dispatch(setActiveMockupCollectionId(0));
    }

    toast.success('Collection successfully deleted.', {
      toastId: 'delete_collection_success',
      position: 'bottom-right',
      autoClose: 5000,
    });
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={setOpen}>
      <AlertDialog.Content style={{ maxWidth: 450 }}>
        <AlertDialog.Title>Delete Collection</AlertDialog.Title>
        <AlertDialog.Description size="2">
          Your collection will be deleted. Mockup templates will remain in the
          "All Mockups" folder. If you use the API, any references to this
          collection will no longer work.
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Cancel>
            <Button
              variant="solid"
              color="red"
              onClick={() => deleteCollectionAction(collectionId)}
            >
              Delete
            </Button>
          </AlertDialog.Cancel>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
});
