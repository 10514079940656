import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { DataModel, DataModelArray, MockupCollection } from './types';
import { Id } from 'react-toastify';

export const mockupCollectionsApi = createApi({
  reducerPath: 'mockupCollectionsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getMockupCollections: builder.query<DataModelArray<MockupCollection>, void>(
      {
        query: () => `mockup-collections`,
      }
    ),
    createMockupCollection: builder.mutation<
      DataModel<MockupCollection>,
      { name: string }
    >({
      query: (payload) => ({
        url: `mockup-collections`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    updateMockupCollection: builder.mutation<
      DataModel<MockupCollection>,
      { id: number; name: string }
    >({
      query: (payload) => ({
        url: `mockup-collections/${payload.id}/rename`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteMockupCollection: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `mockup-collections/${id}`,
        method: 'DELETE',
      }),
    }),
    addToMockupCollection: builder.mutation<
      DataModel<MockupCollection>,
      { mockup_id: number; mockup_collections: number[] }
    >({
      query: (payload) => ({
        url: `mockups/mockup-collections/add`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    removeFromMockupCollection: builder.mutation<
      DataModel<MockupCollection>,
      {
        mockup_id: number;
        mockup_collections: number[];
        activeMockupCollectionId: number;
      }
    >({
      query: (payload) => ({
        url: `mockups/mockup-collections/remove`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
  }),
});

export const {} = mockupCollectionsApi;
