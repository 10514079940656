import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { Button } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setThemeMode } from '@/redux/slices/app';
import { useEffect } from 'react';

export const ToggleThemeButton = () => {
  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector((state) => state.appReducer);

  const setThemeAction = (theme: 'dark' | 'light') => {
    dispatch(setThemeMode(theme));
    window.localStorage.setItem('app-theme', theme);
  };

  return (
    <>
      {themeMode === 'dark' && (
        <Button
          title="Switch to light theme"
          variant="outline"
          color="gray"
          size={'1'}
          onClick={() => setThemeAction('light')}
        >
          <SunIcon
            className="icon"
            width={'15px'}
            height={'15px'}
            cursor={'pointer'}
          />
        </Button>
      )}
      {themeMode === 'light' && (
        <Button
          title="Switch to dark theme"
          variant="outline"
          color="gray"
          size={'1'}
          onClick={() => setThemeAction('dark')}
        >
          <MoonIcon
            width={'15px'}
            height={'15px'}
            cursor={'pointer'}
            color="black"
          />
        </Button>
      )}
    </>
  );
};
