import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { routes } from './index';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  if (!isAuthenticated) {
    sessionStorage.setItem('beforeAuthPath', location.pathname);
    return <Navigate to={routes.LOGIN} state={{ from: location }} />;
  }

  return children;
};
