import { Box, Button, Flex, Text } from '@radix-ui/themes';
import '../view-wrapper.scss';
import { SingleAssetItem } from './SingleAssetItem';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import { DesignAsset, MockupVariationElement } from '@/services/types';
import { useMediaQuery } from 'react-responsive';
import { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { ReactComponent as SwiperLeftArrow } from '@/assets/icons/swiper-left-arrow.svg';
import { ReactComponent as SwiperRightArrow } from '@/assets/icons/swiper-right-arrow.svg';
import {
  setActiveDesignAsset,
  setActiveVariation,
} from '@/redux/slices/collection';
import { Swiper as SwiperType } from 'swiper/types';

interface ILocalPlacementPreview {}

export const LocalPlacementPreview = ({}: ILocalPlacementPreview) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const {
    mockupVariations,
    activeDesignAsset,
    activeSmartObject,
    designAssets,
    activeVariation,
  } = useAppSelector((state) => state.collectionReducer);
  const [currentSlideNumber, setCurrentSlideNumber] = useState<number>(1);
  const [filteredLocalVariations, setFilteredLocalVariations] = useState<
    MockupVariationElement[]
  >([]);

  const sortVariationsByAssetId = (
    variations: MockupVariationElement[],
    variationId: number
  ) => {
    const indexOfProvidedId = variations.findIndex(
      (item) => item.id === variationId
    );

    if (indexOfProvidedId !== -1) {
      const itemWithProvidedId = variations.splice(indexOfProvidedId, 1)[0];
      variations.unshift(itemWithProvidedId);
    }

    return variations;
  };

  const [swiper, setSwiper] = useState<SwiperType>(null!);

  const filterLocalVariations = (sort?: string) => {
    if (filteredLocalVariations.length > 0) {
      if (activeSmartObject?.print_area === 1) {
        const data = mockupVariations.filter((variation) => {
          return variation.variation_elements.some(
            (element) => element.smart_object_id === activeSmartObject?.id
          );
        });

        const filteredLocalVariationsCopy =
          filteredLocalVariations.length === 1
            ? data
            : JSON.parse(JSON.stringify(filteredLocalVariations));

        const arrayOutput: MockupVariationElement[] = [];
        const arrayBMap = new Map(data.map((obj) => [obj.id, obj]));

        for (const objA of filteredLocalVariationsCopy) {
          const objB = arrayBMap.get(objA.id);
          const updatedObj = objB ? { ...objA, ...objB } : objA;

          arrayOutput.push(updatedObj);
        }

        if (sort === 'sort') {
          const sorted = sortVariationsByAssetId(
            arrayOutput,
            activeVariation?.id
          );
          setFilteredLocalVariations(sorted);
          // setFilteredLocalVariations(
          //   sorted.filter((mockup) =>
          //     mockup.variation_elements.every(
          //       (element) =>
          //         !element.asset ||
          //         element.asset.original_design.is_ai_generated !== 1
          //     )
          //   )
          // );
        } else {
          setFilteredLocalVariations(arrayOutput);
          // setFilteredLocalVariations(
          //   arrayOutput.filter((mockup) =>
          //     mockup.variation_elements.every(
          //       (element) =>
          //         !element.asset ||
          //         element.asset.original_design.is_ai_generated !== 1
          //     )
          //   )
          // );
        }
      } else {
        const data = mockupVariations.filter((variation) => {
          return variation.variation_elements.some(
            (element) => element.asset_id === activeDesignAsset?.id
          );
        });
        setFilteredLocalVariations(data);
      }
    }
  };

  const initialFfilterLocalVariations = () => {
    if (activeSmartObject?.print_area === 1) {
      const data = mockupVariations.filter((variation) => {
        return variation.variation_elements.some(
          (element) => element.smart_object_id === activeSmartObject?.id
        );
      });
      const sorted = sortVariationsByAssetId(data, activeVariation?.id);
      setFilteredLocalVariations(sorted);
      // setFilteredLocalVariations(
      //   sorted.filter((mockup) =>
      //     mockup.variation_elements.every(
      //       (element) =>
      //         !element.asset ||
      //         element.asset.original_design.is_ai_generated !== 1
      //     )
      //   )
      // );
    } else {
      const data = mockupVariations.filter((variation) => {
        return variation.variation_elements.some(
          (element) => element.asset_id === activeDesignAsset?.id
        );
      });
      setFilteredLocalVariations(data);
    }
  };

  useEffect(() => {
    initialFfilterLocalVariations();
  }, []);

  useEffect(() => {
    filterLocalVariations();
  }, [mockupVariations]);

  useEffect(() => {
    filterLocalVariations('sort');
    swiper?.update();
  }, [activeSmartObject?.print_area]);

  const setActiveVariationAction = (variation: MockupVariationElement) => {
    dispatch(setActiveVariation(variation));

    const activeVariationAssetId = variation.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;
    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  };

  return (
    <>
      {!isMobile && filteredLocalVariations.length && (
        <Box
          className="local-placement-preview-slider"
          style={{
            position: 'relative',
            padding: '32px 75px',
            width: '100%',
          }}
        >
          <Swiper
            key={`${activeSmartObject?.print_area}`}
            spaceBetween={5}
            slidesPerView={1}
            loop={filteredLocalVariations?.length > 1}
            centeredSlides
            mousewheel
            slideToClickedSlide
            setWrapperSize
            roundLengths
            watchSlidesProgress
            navigation={{
              nextEl: '.swiper-button-next-desktop',
              prevEl: '.swiper-button-prev-desktop',
            }}
            modules={[Navigation, Mousewheel]}
            onUpdate={(swiper) => {
              swiper.slideToLoop(0);
            }}
            onInit={(swiper) => {
              setSwiper(swiper);
              swiper.slideToLoop(0);
            }}
            onSlideChange={(swiper) => {
              const activeSlide = swiper.slides[swiper.activeIndex];
              const activeVariationId = parseInt(
                (activeSlide as any).dataset.variationId
              );

              const variation = mockupVariations.find(
                (variation) => variation.id === activeVariationId
              );
              setActiveVariationAction(variation as MockupVariationElement);

              setCurrentSlideNumber(swiper.realIndex + 1);
            }}
          >
            {filteredLocalVariations?.map((variation, index) => (
              <SwiperSlide
                key={index}
                data-variation-id={variation.id.toString()}
              >
                <SingleAssetItem variation={variation} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Flex
            className="nav-controls"
            align={'center'}
            justify={'center'}
            gap={'5'}
            mt={'4'}
          >
            <Button
              className="swiper-button-prev-desktop"
              color="gray"
              variant="ghost"
              size={'3'}
            >
              <SwiperLeftArrow />
            </Button>

            <Text size={'2'} weight={'regular'}>
              {currentSlideNumber}/{filteredLocalVariations.length}
            </Text>
            <Button
              className="swiper-button-next-desktop"
              color="gray"
              variant="ghost"
              size={'3'}
            >
              <SwiperRightArrow />
            </Button>
          </Flex>
        </Box>
      )}
      {isMobile && (
        <Box
          style={{
            position: 'relative',
            paddingTop: '16px',
            width: '100%',
          }}
        >
          <ChevronLeftIcon className="swiper-button-prev" />
          <Swiper
            spaceBetween={5}
            slidesPerView={1.1}
            loop={filteredLocalVariations?.length > 1}
            height={350}
            centeredSlides
            mousewheel
            slideToClickedSlide
            setWrapperSize
            roundLengths
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Navigation, Mousewheel]}
          >
            {filteredLocalVariations?.map((variation, index) => (
              <SwiperSlide key={index}>
                <SingleAssetItem variation={variation} />
              </SwiperSlide>
            ))}
          </Swiper>
          <ChevronRightIcon className="swiper-button-next" />
        </Box>
      )}
    </>
  );
};
