import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  ColorCombination,
  DataModel,
  DataModelArray,
  Mockup,
  PrivatePsd,
  PrivatePhotoshopFile,
} from './types';

export const designsApi = createApi({
  reducerPath: 'designsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getDesigns: builder.query<DataModelArray<any>, void>({
      query: () => `designs`,
    }),
    uploadDesigns: builder.mutation<DataModelArray<number[]>, FormData>({
      query: (payload) => ({
        url: `designs`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    processPsd: builder.mutation<DataModel<Mockup>, { psd_id: number }>({
      query: (payload) => ({
        url: `psd/process`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePrivatePsd: builder.mutation<DataModel<Mockup>, PrivatePsd>({
      query: (payload) => ({
        url: `psd/user/private-file/update`,
        method: 'POST',
        body: payload,
      }),
    }),
    getMockupSmartObjectColorCombinations: builder.query<
      DataModelArray<ColorCombination>,
      { smart_object_id: number }
    >({
      query: ({ smart_object_id }) =>
        `mockups/smart-objects/${smart_object_id}/colors`,
    }),

    updateColorCombination: builder.mutation<
      DataModel<ColorCombination>,
      Partial<ColorCombination>
    >({
      query: (payload) => ({
        url: `colors/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteDesign: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `designs/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteDesigns: builder.mutation<void, { ids: number[] }>({
      query: (payload) => ({
        url: `designs/delete/multiple`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = designsApi;
