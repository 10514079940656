import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { User } from '@/redux/slices/types';
import { DataModel } from './types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCurrentUser: builder.query<DataModel<User>, void>({
      query: () => `user/info`,
    }),
    updateUserDetails: builder.mutation<DataModel<any>, any>({
      query: (payload) => ({
        url: `user/update-profile`,
        method: 'POST',
        body: payload,
      }),
    }),
    changePassword: builder.mutation<DataModel<any>, any>({
      query: (payload) => ({
        url: `user/change-password`,
        method: 'POST',
        body: payload,
      }),
    }),
    sendVerificationCode: builder.mutation<any, void>({
      query: (payload) => ({
        url: `verification-code/send/change-password`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = userApi;
