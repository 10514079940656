import '../components/view-wrapper/view-wrapper.scss';
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Progress,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { designsApi } from '@/services/designs';
import Uppy from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import { COMPANION_URL } from '@uppy/transloadit';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import XHR from '@uppy/xhr-upload';
import RemoteSources from '@uppy/remote-sources';
import { toast } from 'react-toastify';
import { SingleTemplate } from './view-wrapper/SingleTemplate';
import { Cross1Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { ReactComponent as DesignIcon } from '@/assets/icons/gallery-wide-linear.svg';
import './designs-uploader.scss';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import Selectable, { SelectableRef } from 'react-selectable-box';
import { DeleteDesign } from './view-wrapper/DeleteDesign';
import useKeyPress from '@/hooks/useKeyPress';
import { useMediaQuery } from 'react-responsive';

interface IDesignsUploader {
  selectDesign: (id: number, isSelected: boolean) => void;
  setUppyDashboardInstance: (
    uppy: Uppy<Record<string, unknown>, Record<string, unknown>>
  ) => void;
  handleUploadClick?: () => void;
  setSelectedDesigns: (x: number[]) => void;
  selectedDesigns: number[];
  isDesignsLibrary: boolean;
  deleteDesignsDialog: MutableRefObject<undefined>;
}

export const DesignsUploader = ({
  selectDesign,
  setUppyDashboardInstance,
  handleUploadClick,
  setSelectedDesigns,
  selectedDesigns,
  isDesignsLibrary,
  deleteDesignsDialog,
}: IDesignsUploader) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { designs } = useAppSelector((state) => state.designsReducer);
  const { designAssets, activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );
  const getDesigns = designsApi.useGetDesignsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<any[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [uppyDashboard] = useState(() =>
    new Uppy({
      restrictions: {
        allowedFileTypes: ['.png', '.jpg', '.jpeg', '.webp'], // , '.webp', '.gif'
        maxNumberOfFiles: 1000,
        maxFileSize: 20971520,
      },
      locale: {
        strings: {
          browseFiles: 'Browse files',
          dropPasteBoth:
            '%{browse} or Drag and Drop your files from your device',

          poweredBy: '',
          youCanOnlyUploadX: {
            0: 'You can upload maximum %{smart_count} file at once.',
            1: 'You can upload maximum %{smart_count} files at once.',
          },
          youCanOnlyUploadFileTypes:
            'You can only upload: %{types} types of files.',
        },
      },
    })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: [],
        // sources: ['Dropbox', 'GoogleDrive', 'OneDrive'],
      })
      .on('file-added', (file) => {
        setAddedFilesToUpload((old) => [
          ...old,
          {
            uppyId: file.id,
            fileName: file.name,
            progress: {
              percentage: 0,
              uploadComplete: false,
            },
          },
        ]);

        uppyDashboard.upload();
      })
      .on('upload-success', (file) => {
        // dispatch(
        //   designsApi.endpoints.getDesigns.initiate(null!, {
        //     forceRefetch: true,
        //   })
        // );
        setAddedFilesToUpload((old) =>
          old.map((addedFile) =>
            addedFile.uppyId === file?.id
              ? {
                  ...addedFile,
                  progress: {
                    percentage: 100,
                    uploadComplete: true,
                  },
                }
              : addedFile
          )
        );
      })
      .on('upload-progress', (file) => {
        const { progress } =
          uppyDashboard.getState().files[(file?.id || 0) as number];

        setAddedFilesToUpload((old) =>
          old.map((addedFile) =>
            addedFile.uppyId === file?.id
              ? {
                  ...addedFile,
                  progress: {
                    percentage: progress?.percentage,
                    uploadComplete: false,
                  },
                }
              : addedFile
          )
        );
      })
      .on('restriction-failed', (file, error) => {
        toast.error(error.message, {
          toastId: 'restriction-failed',
          position: 'bottom-right',
          autoClose: 5000,
        });
        captureEvent('Design upload failed', {
          error: error.message,
          tried_to_allocate_size: file?.data?.size
            ? Math.round((file?.data?.size as number) / 1024 / 1024)
            : '/',
        });
      })
      .use(XHR, {
        endpoint: `${API_BASE_URL}designs`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        formData: true,
        allowedMetaFields: [],
        fieldName: 'images[0][file]',
      })
      .on('complete', async (result) => {
        if (result.successful.length) {
          result.successful.forEach((item) => {
            selectDesign((item.response?.body?.data as number[])?.[0], true);
          });
          captureEvent('Designs upload completed', {
            numberOfUploadedFiles: result.successful.length,
          });
          await new Promise((resolve) => setTimeout(resolve, 500));
          dispatch(
            designsApi.endpoints.getDesigns.initiate(null!, {
              forceRefetch: true,
            })
          );
        }
      })
      .on('upload-error', (file, error) => {
        captureEvent('Design upload failed', {
          error: error.message,
          file: {
            name: file?.name,
            size: file?.size,
          },
        });
        dispatch(
          designsApi.endpoints.getDesigns.initiate(null!, {
            forceRefetch: true,
          })
        );
        toast.error(error.message, {
          toastId: 'upload-error',
          position: 'bottom-right',
          autoClose: 5000,
        });

        setAddedFilesToUpload([]);
        // setDesignsStatus('upload-error');
      })
  );

  useEffect(() => {
    setUppyDashboardInstance(uppyDashboard);
  }, [uppyDashboard]);

  useEffect(() => {
    if (isDraggingOver) {
      if (!uppyDashboard.getPlugin('DragDrop')) {
        uppyDashboard.use(DragDrop, {
          target: '#design-dropzone',
        });
      } else {
        uppyDashboard.getPlugin('DragDrop')?.install();
      }
    } else {
      uppyDashboard.getPlugin('DragDrop')?.uninstall();
    }
  }, [isDraggingOver]);

  const selectableRef = useRef<SelectableRef>(null);

  useKeyPress([
    {
      key: 'Escape',
      action: () => {
        setSelectedDesigns([]);
      },
    },
    {
      key: 'a',
      ctrlKey: true,
      action: () => {
        setSelectedDesigns(designs.map((design) => design.id));

        if (isDesignsLibrary) {
          setSelectedDesigns(designs.map((design) => design.id));
        } else {
          const designsIds = designs.map((obj) => obj.id);
          const designsAssetsIds = designAssets[activeSmartObject?.id].map(
            (obj) => obj.original_design_id
          );

          setSelectedDesigns(
            designsIds.filter((id) => !designsAssetsIds.includes(id))
          );
        }
      },
    },
    {
      key: 'd',
      ctrlKey: true,
      action: () => {
        setSelectedDesigns([]);
      },
    },
  ]);

  return (
    <Box
      className={`view-wrapper drag-and-drop-area-wrapper ${
        isDraggingOver ? 'is-dragging-over' : ''
      }`}
      id="design-dropzone"
      onDragEnterCapture={() => {
        if (!isDraggingOver) {
          setIsDraggingOver(true);
        }
      }}
      onDropCapture={() => {
        if (isDraggingOver) {
          setIsDraggingOver(false);
          captureEvent('Upload Design initiated', {
            type: 'drag-and-drop',
          });
        }
      }}
      onDragLeaveCapture={(event) => {
        const { target } = event;
        if (target instanceof HTMLElement) {
          if (target.classList.contains('uppy-u-reset')) {
            setIsDraggingOver(false);
          }
        }
      }}
    >
      <Selectable
        disabled={isMobile}
        ref={selectableRef}
        scrollContainer={() =>
          document.getElementById('scroll-container') as HTMLElement
        }
        value={selectedDesigns}
        onEnd={(selectingValue, { added, removed }) => {
          const result = selectedDesigns
            .concat(added)
            .filter((i) => !removed.includes(i));
          setSelectedDesigns(result);
        }}
        dragContainer={() =>
          document.getElementById('selection-box-container') as HTMLElement
        }
      >
        <Grid className={`view-wrapper designs-wrapper`} gap={'4'} px={'6'}>
          {getDesigns.isLoading
            ? [...Array(14)].map((item, index) => (
                <Skeleton
                  key={`${index}-item`}
                  className="single-design skeleton"
                />
              ))
            : designs?.map((design, index) => (
                <SingleTemplate
                  key={index}
                  design={design}
                  isDesignsLibrary={isDesignsLibrary}
                  deleteDesignsDialog={deleteDesignsDialog}
                  onClick={(isSelected) => {
                    if (isSelected) {
                      setSelectedDesigns(
                        selectedDesigns.filter((val) => val !== design.id)
                      );
                    } else {
                      setSelectedDesigns(selectedDesigns.concat(design.id));
                    }
                  }}
                />
              ))}
          {addedFilesToUpload
            .filter((file) => !file.progress?.uploadComplete)
            ?.map((item, index) => (
              <Box
                key={`${index}-loader`}
                className="single-template-loader-uploader"
              >
                <Skeleton className="single-design skeleton" />
                <Progress value={item.progress?.percentage} />
              </Box>
            ))}
          <Box className="uppy-Dashboard-dropFilesHereHint">
            Drop your files here
          </Box>
        </Grid>
      </Selectable>
      {!getDesigns.isLoading &&
        designs?.length === 0 &&
        addedFilesToUpload?.length === 0 && (
          <Flex
            direction={'column'}
            align={'center'}
            justify={'center'}
            gap={'4'}
            className="empty-page-state"
          >
            <Flex align={'center'} justify={'center'} className="cercle-wrap">
              <DesignIcon className="svg-icon" />
            </Flex>
            <Text size="2" weight="regular" align={'center'}>
              Store your Designs in a central place so you can reuse them in
              various Mockups
            </Text>
            <Button
              variant="solid"
              onClick={() => handleUploadClick && handleUploadClick()}
            >
              <PlusIcon className="icon white" />
              <Text size="1" weight="medium">
                Upload Designs
              </Text>
            </Button>
          </Flex>
        )}
    </Box>
  );
};
