import { designsApi } from '@/services/designs';
import { Design } from '@/services/types';
import { createSlice } from '@reduxjs/toolkit';

interface IDesignsState {
  designs: Design[];
}

const initialState: IDesignsState = {
  designs: null!,
};

export const designsSlice = createSlice({
  initialState,
  name: 'designsSlice',
  reducers: {
    setDesigns(state, { payload }) {
      state.designs = payload;
    },
    resetEditorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      designsApi.endpoints.getDesigns.matchFulfilled,
      (state, response) => {
        state.designs = response.payload.data?.map((design) => {
          return { ...design, selected: false };
        });
      }
    );
  },
});

export default designsSlice.reducer;

export const { setDesigns } = designsSlice.actions;
