import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/hooks';
import { Project } from '@/services/types';
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { workspaceApi } from '@/services/workspace';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IFormInput {
  mockupName: string;
}

interface IEditPhotoshopFile {
  project: Project;
}

export const EditPhotoshopFile = forwardRef(
  ({ project }: IEditPhotoshopFile, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);
    const [updateMockupIsLoading, setUpdateMockupIsLoading] = useState(false);
    const [mockupName, setMockupName] = useState<string>(project.name);

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = (data) =>
      updateMockupAction(data);

    const updateMockupAction = async (formInput: IFormInput) => {
      try {
        setUpdateMockupIsLoading(true);
        const data = {
          id: project.id,
          name: mockupName,
        };
        await dispatch(
          workspaceApi.endpoints.updateMyProject.initiate(data)
        ).unwrap();

        captureEvent('Template renamed');

        toast.success('Template successfully updated.', {
          toastId: 'update_mockup_success',
          position: 'bottom-right',
          autoClose: 5000,
        });
      } catch (error: any) {
        toast.error(error.data.message, {
          toastId: 'update_mockup_error',
          position: 'bottom-right',
          autoClose: 7000,
        });
      } finally {
        setUpdateMockupIsLoading(false);
      }
    };

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
          <Dialog.Title>
            <Flex direction={'row'} align={'center'} justify={'between'}>
              <Text>Edit Template</Text>
            </Flex>
          </Dialog.Title>
          <Flex direction={'column'}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              ref={formRef}
            >
              <label>
                <TextField.Slot>
                  <Text weight={'medium'} size={'2'} mb={'1'}>
                    Mockup Name
                  </Text>
                </TextField.Slot>
                <TextField.Root
                  placeholder="Mockup Name"
                  value={mockupName}
                  onChange={(e) => setMockupName(e.currentTarget.value)}
                />
              </label>
              {errors.mockupName && (
                <Text role="alert" size={'1'} color="red">
                  {errors.mockupName.message}
                </Text>
              )}
              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Dialog.Close>
                  <Button type="submit" loading={updateMockupIsLoading}>
                    Save Changes
                  </Button>
                </Dialog.Close>
              </Flex>
            </form>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    );
  }
);
