import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';
import baseQuery from './baseQuery';

const sequentialBaseQueryFactory = (mutex: InstanceType<typeof Mutex>) => {
  const sequentialBaseQuery: BaseQueryFn<
    string | FetchArgs,
    any,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    const releaseSequence = await mutex.acquire();
    const result = await baseQuery(args, api, extraOptions);
    releaseSequence();
    return result;
  };
  return sequentialBaseQuery;
};

const endpointSequenceMutex = new Mutex();
export const EndpointSequentialBaseQuery = sequentialBaseQueryFactory(
  endpointSequenceMutex
);
