import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/hooks';
import { MockupCollection } from '@/services/types';
import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  SetStateAction,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { mockupCollectionsApi } from '@/services/mockupCollections';

interface IFormInput {
  collectionName: string;
}

interface IEditCollection {}

export const EditCollection = forwardRef(({}: IEditCollection, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const [open, setOpen] = useState<boolean>(false);
  const [updateMockupIsLoading, setUpdateCollectionIsLoading] = useState(false);
  const [collectionName, setCollectionName] = useState<string>('');
  const [collectionId, setCollectionId] = useState<number>(0);

  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openDialog(collection: MockupCollection) {
      setCollectionName(collection.name);
      setCollectionId(collection.id);
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updateCollectionAction(data);

  const updateCollectionAction = async (formInput: IFormInput) => {
    try {
      setUpdateCollectionIsLoading(true);
      const data = {
        id: collectionId,
        name: collectionName,
      };
      await dispatch(
        mockupCollectionsApi.endpoints.updateMockupCollection.initiate(data)
      ).unwrap();

      dispatch(
        mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
          forceRefetch: true,
        })
      );
      captureEvent('Collection edited', { ...data });

      toast.success('Collection successfully renamed.', {
        toastId: 'rename_collection_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'rename_collection_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } finally {
      setUpdateCollectionIsLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
        <Dialog.Title>
          <Flex direction={'row'} align={'center'} justify={'between'}>
            <Text>Rename Collection</Text>
          </Flex>
        </Dialog.Title>
        <Flex direction={'column'}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={formRef}
          >
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'2'} mb={'1'}>
                  Collection Name
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="T-shirts"
                value={collectionName}
                onChange={(e) => setCollectionName(e.currentTarget.value)}
              />
            </label>
            {errors.collectionName && (
              <Text role="alert" size={'1'} color="red">
                {errors.collectionName.message}
              </Text>
            )}
            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button type="submit" loading={updateMockupIsLoading}>
                  Save Changes
                </Button>
              </Dialog.Close>
            </Flex>
          </form>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
});
