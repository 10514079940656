import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { ColorCombination, DataModel, DataModelArray } from './types';

export const aiBackgroundsApi = createApi({
  reducerPath: 'aiBackgroundsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    generateAiBackgroundImage: builder.query<
      DataModel<any>,
      {
        mockup_id: number;
        prompt: string;
        negative_prompt: string | null;
        smart_object_id: number;
      }
    >({
      query: (payload) => ({
        url: `ai/background/image`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = aiBackgroundsApi;
