import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  DataModel,
  DataModelArray,
  Mockup,
  PrivatePsd,
  PrivatePhotoshopFile,
  PsdCategory,
  PsdAvailability,
  SubscriptionType,
} from './types';

export const psdManagementApi = createApi({
  reducerPath: 'psdManagementApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    uploadPsd: builder.mutation<DataModel<Mockup>, FormData>({
      query: (payload) => ({
        url: `psd/upload`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    processPsd: builder.mutation<DataModel<Mockup>, { psd_id: number }>({
      query: (payload) => ({
        url: `psd/process`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePrivatePsd: builder.mutation<DataModel<Mockup>, PrivatePsd>({
      query: (payload) => ({
        url: `psd/user/private-file/update`,
        method: 'POST',
        body: payload,
      }),
    }),
    getPrivatePsds: builder.query<DataModelArray<PrivatePhotoshopFile>, void>({
      query: () => `psd/user/private-files`,
    }),
    deletePrivatePsd: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `psd/${id}`,
        method: 'DELETE',
      }),
    }),
    getPsdCategories: builder.query<DataModelArray<PsdCategory>, void>({
      query: () => `psd_categories`,
    }),
    getPsdAvailabilities: builder.query<DataModelArray<PsdAvailability>, void>({
      query: () => `psd/availabilities`,
    }),
    getSubscriptionTypes: builder.query<DataModelArray<SubscriptionType>, void>(
      {
        query: () => `subscription-types`,
      }
    ),
  }),
});

export const {} = psdManagementApi;
