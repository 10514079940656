import { useEffect } from 'react';
import { useAppDispatch } from '@/hooks';
import { authApi } from '@/services/auth';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export const AutoAuth = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const url = await dispatch(
        authApi.endpoints.googleAuth.initiate()
      ).unwrap();

      window.location.replace(url.data);
    };
    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    </>
  );
};
