import { Box, Flex, Text } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { SingleAssetItem } from './SingleAssetItem';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { psdEngineApi } from '@/services/psdEngine';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  setActiveVariation,
  setIsMockupLoading,
} from '@/redux/slices/collection';
import { Navigation, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { MockupVariationElement } from '@/services/types';
import { MainImagePreview } from './MainImagePreview';
import { workspaceApi } from '@/services/workspace';
import { useMediaQuery } from 'react-responsive';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IMainPreview {}

export const MainPreview = ({}: IMainPreview) => {
  const { collectionId } = useParams();
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    activeSmartObject,
    mockupVariations,
    mockup,
    isCreatingVariations,
    activeVariation,
    isMockupLoading,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  const isParsableNumber = (value: any) => {
    // Check if the value is a string
    if (typeof value !== 'string') {
      return false;
    }

    // Convert the string to a number
    const number = Number(value);

    // Check if the result is a finite number
    return Number.isFinite(number);
  };

  const getMockupVariations = psdEngineApi.useGetMockupVariationsQuery(
    {
      mockup_id: mockup?.id,
    },
    {
      skip: mockupVariations.length > 0 || !mockup?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (!isParsableNumber(collectionId)) {
      //  if (!getMockupVariations.isLoading) {
      dispatch(setIsMockupLoading(false));
    }
  }, [collectionId]);

  const setActiveVariationAction = (variation: MockupVariationElement) => {
    dispatch(setActiveVariation(variation));
  };

  return (
    <>
      <Flex></Flex>
      {!isMobile && (
        <Flex className="main-slide-center" align={'center'} justify={'center'}>
          {!getMockupVariations.isLoading &&
          !isMockupLoading &&
          activeSmartObject ? (
            mockupVariations.length > 0 ? (
              <MainImagePreview
                image={activeVariation?.export_path}
                isFetching={activeVariation?.isFetching || false}
                height="65vh"
                isVariationPreview={true}
              />
            ) : (
              <Flex
                className="swiper-slide-transform"
                align={'center'}
                justify={'center'}
                width={'100%'}
                height={'100%'}
              >
                {/* {mockup?.subscription_type_id === 2 && (
                  <ProCrownIcon
                    className="pro-icon"
                    width="24px"
                    height="24px"
                  />
                )} */}
                {/* {!isAppPro && (
                  <Flex
                    className="watermark-button"
                    onClick={() => {
                      dispatch(setIsAppPaymentPopupOpen(true));
                      captureEvent('Upgrade your plan modal viewed', {
                        source: 'Watermark',
                      });
                    }}
                  ></Flex>
                )} */}

                <LazyLoadImage
                  height={'auto'}
                  width={'auto'}
                  style={{
                    objectFit: 'contain',
                    filter: activeVariation?.isFetching
                      ? 'blur(3px)'
                      : 'blur(0)',
                    maxHeight: '65vh',
                  }}
                  effect="blur"
                  src={
                    mockup?.thumbnail ||
                    'https://placehold.co/300x300?text=MOCKUP_' + mockup?.id
                  }
                  delayTime={0}
                  placeholderSrc={
                    mockup?.thumbnail ||
                    'https://placehold.co/300x300?text=MOCKUP_' + mockup?.id
                  }
                />
              </Flex>
            )
          ) : (
            <Box
              width={'100%'}
              height={'100%'}
              style={{ position: 'relative' }}
            >
              <Skeleton
                width={'100%'}
                height={'100%'}
                style={{ lineHeight: 'unset', height: '65vh' }}
              />
              {collectionId == 'loading' && (
                <Text
                  style={{
                    position: 'absolute',
                    zIndex: '200',
                    color: '#6F6F6F',
                    fontSize: '14px',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  Please wait while the Mockup loads 🙏
                </Text>
              )}
            </Box>
          )}
        </Flex>
      )}
      <Flex
        className="multiple-slide-bottom"
        direction={'row'}
        align={!isCreatingVariations ? 'center' : 'stretch'}
        justify={'center'}
        mb={'4'}
      >
        {!getMockupVariations.isLoading &&
        !isMockupLoading &&
        !!activeSmartObject ? (
          <>
            <ChevronLeftIcon className="swiper-button-prev" />
            <Swiper
              spaceBetween={5}
              slidesPerView={1.1}
              loop={
                isMobile
                  ? mockupVariations.length > 1
                  : mockupVariations.length > 7.5
              }
              height={350}
              centeredSlides
              mousewheel
              slideToClickedSlide
              setWrapperSize
              roundLengths
              breakpoints={{
                480: {
                  spaceBetween: 10,
                  slidesPerView: 7.5,
                  height: 80,
                  centeredSlides: true,
                  mousewheel: true,
                  slideToClickedSlide: true,
                  setWrapperSize: true,
                  roundLengths: true,
                  freeMode: {
                    enabled: true,
                    sticky: true,
                  },
                },
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[Navigation, Mousewheel, FreeMode]}
              onInit={(swiper) => {
                const activeVariationSlide = swiper.slides.filter(
                  (slide) =>
                    parseInt((slide as any).dataset.variationId) ===
                    activeVariation?.id
                )[0];

                const dataSlideIndex = parseInt(
                  (activeVariationSlide as any)?.dataset.slideIndex
                );

                swiper.slideToLoop(dataSlideIndex || 0);
              }}
              onSlideChange={(swiper) => {
                const activeSlide = swiper.slides[swiper.activeIndex];
                const activeVariationId = parseInt(
                  (activeSlide as any).dataset.variationId
                );
                const variation = mockupVariations.find(
                  (variation) => variation.id === activeVariationId
                );

                setActiveVariationAction(variation as MockupVariationElement);
              }}
            >
              {!isCreatingVariations ? (
                mockupVariations.length > 0 ? (
                  mockupVariations.map((variation, index) => (
                    <SwiperSlide
                      key={index}
                      data-variation-id={variation.id.toString()}
                      data-slide-index={index.toString()}
                      onClick={() => {
                        setActiveVariationAction(variation);
                      }}
                    >
                      <Box
                        style={{
                          border:
                            activeVariation?.id === variation.id
                              ? '1px solid var(--blue-9)'
                              : undefined,
                        }}
                        className="swiper-slide-transform"
                      >
                        <SingleAssetItem variation={variation} />
                      </Box>
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide key={0}>
                    <Box className="swiper-slide-transform">
                      <Flex
                        className="single-asset-item-wrapper"
                        justify={'center'}
                        align={'center'}
                      >
                        <LazyLoadImage
                          height={'100%'}
                          width={'100%'}
                          style={{
                            objectFit: 'contain',
                          }}
                          effect="blur"
                          src={mockup?.thumbnail}
                          delayTime={0}
                          placeholderSrc={mockup?.thumbnail}
                        />
                      </Flex>
                    </Box>
                  </SwiperSlide>
                )
              ) : (
                [...Array(6)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <Skeleton
                      style={{
                        width: '100%',
                        height: '100%',
                        minHeight: isMobile ? '300px' : '68px',
                        maxHeight: isMobile ? '300px' : undefined,
                        minWidth: '100px',
                      }}
                    ></Skeleton>
                  </SwiperSlide>
                ))
              )}
            </Swiper>
            <ChevronRightIcon className="swiper-button-next" />
          </>
        ) : (
          <>
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} className="multiple-skeleton"></Skeleton>
            ))}
          </>
        )}
      </Flex>
    </>
  );
};
