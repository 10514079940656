import { userApi } from '@/services/user';
import { createSlice } from '@reduxjs/toolkit';
import { User } from './types';

interface IUserState {
  currentUser: User | null;
  userLoading: boolean;
}

const initialState: IUserState = {
  currentUser: null,
  userLoading: true,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getCurrentUser.matchFulfilled,
        (state, response) => {
          state.currentUser = response.payload.data;
          state.userLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.getCurrentUser.matchRejected,
        (state, response) => {
          state.userLoading = false;
        }
      );
  },
});

export default userSlice.reducer;

export const {} = userSlice.actions;
