import { Cross1Icon } from '@radix-ui/react-icons';
import {
  Dialog,
  Button,
  Flex,
  Grid,
  Box,
  Text,
  ScrollArea,
  Badge,
  Tabs,
  VisuallyHidden,
} from '@radix-ui/themes';
import { ReactComponent as DCLogo } from '@/assets/dm-logo-vector-api-payments.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/checkmark.svg';
import './apiPpaymentPopup.scss';
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { paymentsApi } from '@/services/payments';

interface IApiPaymentPopup {
  children: ReactNode;
}

export interface MonthlyPlan {
  amount: string;
  discountedPrice?: string;
  price?: string;
  fullPrice?: string;
  discountedMonthlyPrice?: string;
  discount?: string;
  saved?: string;
  selected: boolean;
  stripeLink: string;
}

export const monthlyPlansDataDEV = [
  {
    amount: '500',
    price: '49',
    selected: false,
    stripeLink: 'price_1Pso8TFy4AZwJXspDreInULd',
  },
  {
    amount: '10.000',
    price: '749',
    selected: false,
    stripeLink: 'price_1Pso9kFy4AZwJXspaEBT5Adc',
  },
];

export const yearlyPlansDataDEV = [
  {
    amount: '500',
    discountedPrice: '441',
    fullPrice: '588',
    discountedMonthlyPrice: '37',
    discount: '25%',
    saved: '147',
    selected: false,
    stripeLink: 'price_1Pso8TFy4AZwJXspCFPurqZD',
  },
  {
    amount: '10.000',
    discountedPrice: '6,741',
    fullPrice: '8,988',
    discountedMonthlyPrice: '562',
    discount: '25%',
    saved: '2,247',
    selected: false,
    stripeLink: 'price_1Pso9kFy4AZwJXsp8Fyxa5Ut',
  },
];

export const monthlyPlansData = [
  {
    amount: '1.000',
    price: '49',
    selected: false,
    stripeLink: 'price_1NiLd5Fy4AZwJXsp73qo1jWq',
  },
  {
    amount: '2.500',
    price: '99',
    selected: false,
    stripeLink: 'price_1Q7YN8Fy4AZwJXspS6mSq2ID',
  },
  {
    amount: '5.000',
    price: '149',
    selected: false,
    stripeLink: 'price_1Q7YIqFy4AZwJXspS4v5AhhY',
  },
  {
    amount: '10.000',
    price: '249',
    selected: false,
    stripeLink: 'price_1Q7YA9Fy4AZwJXspGJpAAW38',
  },
];

export const yearlyPlansData = [
  {
    amount: '1.000',
    discountedPrice: '432',
    fullPrice: '588',
    discountedMonthlyPrice: '36',
    discount: '25%',
    saved: '156',
    selected: false,
    stripeLink: 'price_1Q7YU3Fy4AZwJXspbFHu8ghP',
  },
  {
    amount: '2.500',
    discountedPrice: '888',
    fullPrice: '1,188',
    discountedMonthlyPrice: '74',
    discount: '25%',
    saved: '300',
    selected: false,
    stripeLink: 'price_1Q7YNgFy4AZwJXspISDdPLIL',
  },
  {
    amount: '5.000',
    discountedPrice: '1,332',
    fullPrice: '1,788',
    discountedMonthlyPrice: '111',
    discount: '25%',
    saved: '456',
    selected: false,
    stripeLink: 'price_1Q7YJRFy4AZwJXsp4J0zPJRy',
  },
  {
    amount: '10.000',
    discountedPrice: '2,232',
    fullPrice: '2,988',
    discountedMonthlyPrice: '186',
    discount: '25%',
    saved: '756',
    selected: false,
    stripeLink: 'price_1Q7YBJFy4AZwJXsphheGlBUE',
  },
];

export const ApiPaymentPopup = forwardRef(
  ({ children }: IApiPaymentPopup, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const { currentUser } = useAppSelector((state) => state.userReducer);
    const { userRenders } = useAppSelector((state) => state.paymentsReducer);

    const [monthlyPlans, setMonthlyPlans] = useState<MonthlyPlan[]>(
      !!parseInt(IS_PRODUCTION) ? monthlyPlansData : monthlyPlansDataDEV
    );
    const [yearlyPlans, setYearlyPlans] = useState<MonthlyPlan[]>(
      !!parseInt(IS_PRODUCTION) ? yearlyPlansData : yearlyPlansDataDEV
    );

    useEffect(() => {
      if (currentUser) {
        let updatedMonthlyPlans = monthlyPlans.map((plan) => ({
          ...plan,
          stripeLink: plan.stripeLink,
        }));
        setMonthlyPlans(updatedMonthlyPlans);

        let updatedYearlyPlans = yearlyPlans.map((plan) => ({
          ...plan,
          stripeLink: plan.stripeLink,
        }));
        setYearlyPlans(updatedYearlyPlans);
      }
    }, [currentUser]);

    // useEffect(() => {
    //   if (userRenders) {
    //     let monthlyPlansCopy = [...monthlyPlans];
    //     let yearlyPlansCopy = [...yearlyPlans];
    //     let count = 0;
    //     for (let plan of monthlyPlansCopy) {
    //       if (
    //         plan.amount !== userRenders.plan.toString() &&
    //         userRenders?.interval === 'monthly' &&
    //         count < 1
    //       ) {
    //         plan.selected = true;
    //         count++;
    //       } else {
    //         plan.selected = false;
    //       }
    //     }

    //     for (let plan of yearlyPlansCopy) {
    //       if (
    //         plan.amount !== userRenders.plan.toString() &&
    //         userRenders?.interval === 'yearly' &&
    //         count < 1
    //       ) {
    //         plan.selected = true;
    //         count++;
    //       } else {
    //         plan.selected = false;
    //       }
    //     }

    //     setMonthlyPlans(monthlyPlansCopy);
    //     setYearlyPlans(yearlyPlansCopy);
    //   }
    // }, [userRenders]);

    const getSelectedPlan = () => {
      if (tabValue === 'Monthly') {
        return monthlyPlans.find((plan) => plan.selected);
      } else {
        return yearlyPlans.find((plan) => plan.selected);
      }
    };

    const selectMonthlyPlan = (plan: MonthlyPlan) => {
      if (
        parseInt(plan.amount) === userRenders?.plan &&
        userRenders?.interval === 'monthly'
      ) {
        return;
      }
      const updatedPlans = monthlyPlans.map((item) => ({
        ...item,
        selected: item.amount === plan.amount,
      }));
      captureEvent(
        `Plan ${plan.amount} Monthly Renders, $${plan.price} / month selected`
      );

      setMonthlyPlans(updatedPlans);
    };

    const selectYearlyPlan = (plan: MonthlyPlan) => {
      if (
        parseInt(plan.amount) === userRenders?.plan &&
        userRenders?.interval === 'yearly'
      ) {
        return;
      }
      const updatedPlans = yearlyPlans.map((item) => ({
        ...item,
        selected: item.amount === plan.amount,
      }));
      captureEvent(
        `Plan ${plan.amount} Monthly Renders, $${plan.discountedPrice} / year selected`
      );

      setYearlyPlans(updatedPlans);
    };

    const goToStripePlanCheckout = async () => {
      try {
        const url = await dispatch(
          paymentsApi.endpoints.getCheckoutSessionURL.initiate({
            stripe_price_id: getSelectedPlan()?.stripeLink || '',
            subscription_plan_type: 'api',
          })
        ).unwrap();
        captureEvent('Payment initiated');
        window.location.replace(url.data.checkout_url);
      } catch (error) {
        captureEvent('Payment initiation failed');
        console.log(error);
      }
    };

    const [tabValue, setTabValue] = useState<string>('Monthly');

    const [open, setOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger>{children}</Dialog.Trigger>

        <Dialog.Content
          aria-describedby={undefined}
          className="api-payment-popup-wrapper"
          style={{ maxWidth: 824, maxHeight: 526, padding: '8px' }}
          onPointerDownOutside={() => captureEvent('API Plans modal closed')}
        >
          <VisuallyHidden>
            <Dialog.Title mb={'0'}>
              <Text size={'5'} weight={'bold'}></Text>
            </Dialog.Title>
          </VisuallyHidden>
          <Grid columns={isMobile ? '1fr' : '3fr 3fr'} rows={'1fr'} gap={'2'}>
            <Flex direction={'column'} justify={'between'} p={'5'}>
              <DCLogo style={{ margin: '0 auto' }} />
              <Flex className="title-wrapper" direction={'column'} gap={'4'}>
                <Text weight={'regular'}>API Integration</Text>
                <Text weight={'regular'}>Dynamic Mockups</Text>
                <Text weight={'light'}>
                  Bring designs to life with realistic Mockups on your website.
                  Use the Public Library or your Photoshop template.
                </Text>
              </Flex>
            </Flex>
            <Flex className="content-wrapper" direction={'column'}>
              <Flex justify={'between'} align={'center'} p={'2'}>
                <Text
                  size={'2'}
                  weight={'bold'}
                  align={'center'}
                  style={{ width: '100%' }}
                >
                  Choose your Plan
                </Text>
                <Dialog.Close>
                  <Cross1Icon
                    cursor={'pointer'}
                    onClick={() => captureEvent('API Plans modal closed')}
                  />
                </Dialog.Close>
              </Flex>

              <Tabs.Root
                value={tabValue}
                onValueChange={(val) => {
                  captureEvent(`${val} Plan Category viewed`);
                  setTabValue(val);
                }}
              >
                <Tabs.List>
                  <Tabs.Trigger value="Monthly">Monthly</Tabs.Trigger>
                  <Tabs.Trigger value="Yearly">
                    Yearly{' '}
                    <Badge color="green" className="save-badge">
                      SAVE 25%
                    </Badge>
                  </Tabs.Trigger>
                </Tabs.List>

                <Box pt="1">
                  <Tabs.Content value="Monthly">
                    <ScrollArea
                      type="hover"
                      scrollbars="vertical"
                      style={{ height: '358px' }}
                    >
                      <Flex px={'5'} py={'4'} direction={'column'} gap={'2'}>
                        {monthlyPlans.map((plan) => (
                          <Flex
                            key={plan.amount}
                            className={`pricing-item ${
                              plan.selected ? 'active' : ''
                            } ${
                              parseInt(plan.amount) === userRenders?.plan &&
                              userRenders?.interval === 'monthly'
                                ? 'current-plan'
                                : ''
                            }`}
                            justify={'between'}
                            align={'center'}
                            px={'2'}
                            py={'3'}
                            onClick={() => selectMonthlyPlan(plan)}
                          >
                            <Flex direction={'column'}>
                              <Text size={'1'} weight={'bold'}>
                                {plan.amount} Monthly Renders
                              </Text>
                              <Text
                                size={'1'}
                                weight={'medium'}
                                className="price"
                              >
                                {`$${plan.price}`} / month
                              </Text>
                            </Flex>
                            {plan.selected && <CheckmarkIcon />}
                            {parseInt(plan.amount) === userRenders?.plan &&
                              userRenders?.interval === 'monthly' && (
                                <Badge
                                  color="gray"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  CURRENT PLAN
                                </Badge>
                              )}
                          </Flex>
                        ))}
                      </Flex>
                    </ScrollArea>
                  </Tabs.Content>
                  <Tabs.Content value="Yearly">
                    <ScrollArea
                      type="hover"
                      scrollbars="vertical"
                      style={{ height: '358px' }}
                    >
                      <Flex px={'5'} py={'4'} direction={'column'} gap={'2'}>
                        {yearlyPlans.map((plan) => (
                          <Flex
                            key={plan.amount}
                            className={`pricing-item ${
                              plan.selected ? 'active' : ''
                            } ${
                              parseInt(plan.amount) === userRenders?.plan &&
                              userRenders?.interval === 'yearly'
                                ? 'current-plan'
                                : ''
                            }`}
                            justify={'between'}
                            align={'center'}
                            px={'2'}
                            py={'3'}
                            onClick={() => selectYearlyPlan(plan)}
                          >
                            <Flex direction={'column'}>
                              <Text size={'1'} weight={'bold'}>
                                {plan.amount} Monthly Renders
                              </Text>
                              <Text
                                size={'1'}
                                weight={'medium'}
                                className="price"
                              >
                                {`$${plan.discountedPrice}`} / year
                              </Text>
                            </Flex>
                            {plan.selected && <CheckmarkIcon />}
                            {parseInt(plan.amount) === userRenders?.plan &&
                              userRenders?.interval === 'yearly' && (
                                <Badge
                                  color="gray"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  CURRENT PLAN
                                </Badge>
                              )}
                          </Flex>
                        ))}
                      </Flex>
                    </ScrollArea>
                  </Tabs.Content>
                </Box>
              </Tabs.Root>

              <Box p={'4'}>
                <Button
                  color="blue"
                  variant="solid"
                  style={{ width: '100%' }}
                  onClick={() => goToStripePlanCheckout()}
                >
                  Subscribe
                </Button>
              </Box>
            </Flex>
          </Grid>
        </Dialog.Content>
      </Dialog.Root>
    );
  }
);
