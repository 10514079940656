import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { PublicCatalog, Project, DataModelArray, DataModel } from './types';
import { Workspace } from '@/redux/slices/types';

export const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCurentUserWorkspaceInfo: builder.query<DataModel<Workspace>, void>({
      query: () => `user/workspace-info`,
      transformResponse: (response: DataModel<Workspace>) => {
        return response;
      },
    }),
    getPublicCatalogs: builder.query<
      DataModelArray<PublicCatalog>,
      { categoryId: number }
    >({
      query: ({ categoryId }) =>
        `mockup/catalogs/public${
          categoryId ? '?psd_category_id=' + categoryId : ''
        }`,
    }),
    // getMyProjects: builder.query<DataModelArray<Project>, void>({
    //   query: () => `mockup/catalogs/my_projects`,
    // }),
    getMyProjects: builder.query<
      DataModelArray<Project>,
      { collectionId?: number }
    >({
      query: (payload) =>
        `mockup/catalogs/my_projects${
          payload?.collectionId
            ? `?mockup_collection_id=${payload?.collectionId}`
            : ''
        }`,
    }),
    deleteMyProject: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `mockup/catalogs/my_projects/${id}/delete`,
        method: 'DELETE',
      }),
    }),
    updateMyProject: builder.mutation<
      DataModel<Project>,
      { id: number; name: string }
    >({
      query: (payload) => ({
        url: `/mockups/${payload.id}/rename`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {} = workspaceApi;
