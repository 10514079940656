import './enterprise-contact-us.scss';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import {
  Text,
  Dialog,
  Flex,
  Button,
  Select,
  TextField,
  VisuallyHidden,
  CheckboxCards,
} from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right.svg';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAppSelector } from '@/hooks';
import posthog from 'posthog-js';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';

interface IFormInput {
  firstName: string;
  lastName: string;
  workEmailAddress: string;
  company_type: string;
  company_size: string;
  job_title: string;
  imagesPerYear: number;
  secondary_use_cases: string[];
}

interface IEnterpriseContactUs {
  goToPayWall: () => void;
  closeFormModal: () => void;
}

export const EnterpriseContactUs = ({
  goToPayWall,
  closeFormModal,
}: IEnterpriseContactUs) => {
  const captureEvent = useCaptureEvent();
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IFormInput>({
    defaultValues: {
      firstName: posthog.persistence?.get_property('$stored_person_properties')
        ?.first_name,
      lastName: posthog.persistence?.get_property('$stored_person_properties')
        ?.last_name,
      company_type: posthog.persistence?.get_property(
        '$stored_person_properties'
      )?.company_type,
      company_size: posthog.persistence?.get_property(
        '$stored_person_properties'
      )?.company_size,
      job_title: posthog.persistence?.get_property('$stored_person_properties')
        ?.job_title,
      secondary_use_cases: posthog.persistence
        ?.get_property('$stored_person_properties')
        ?.secondary_use_cases?.split(';'),
    },
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) => submitOnboarding(data);

  const submitOnboarding = async (formInput: IFormInput) => {
    const data = {
      work_email_address: formInput.workEmailAddress,
      first_name: formInput.firstName,
      last_name: formInput.lastName,
      company_type: formInput.company_type,
      company_size: formInput.company_size,
      job_title: formInput.job_title,
      estimated_number_of_images_per_year: formInput.imagesPerYear,
      secondary_use_cases: formInput.secondary_use_cases.join(';'),
    };
    captureEvent('Enterprise Plan Form submitted', { $set: data });
    closeFormModal();
  };

  return (
    <Flex>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Flex
          className="onboarding-wrapper step-2"
          direction={'column'}
          gap="5"
          justify={'between'}
          height={'100%'}
        >
          <Flex
            direction={'row'}
            gap={'4'}
            align={'center'}
            justify={'between'}
          >
            <Button
              variant="ghost"
              color="gray"
              className="back-button"
              size={'1'}
              onClick={() => goToPayWall()}
            >
              <ArrowLeft width="14px" height="14px" className="icon" />
            </Button>
            <Text size={'6'} weight={'bold'}>
              Enterprise Plan
            </Text>
            <Text size={'6'} weight={'bold'}></Text>
          </Flex>
          <Flex
            direction={'column'}
            gap="2px"
            justify={'between'}
            height={'100%'}
            className="step-2-items"
          >
            <label>
              <TextField.Slot>
                <Text weight={'medium'} color="gray" size={'1'} mb={'1'}>
                  Work Email Address
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="example@example.com"
                {...register('workEmailAddress', {
                  required: {
                    value: true,
                    message: 'Work Email Address field is required',
                  },
                })}
              />
            </label>
            {errors.workEmailAddress && (
              <Text role="alert" size={'1'} color="red">
                {errors.workEmailAddress.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  First Name
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="John"
                {...register('firstName', {
                  required: {
                    value: true,
                    message: 'First Name field is required',
                  },
                })}
              />
            </label>
            {errors.firstName && (
              <Text role="alert" size={'1'} color="red">
                {errors.firstName.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  Last Name
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="Doe"
                {...register('lastName', {
                  required: {
                    value: true,
                    message: 'Last Name field is required',
                  },
                })}
              />
            </label>
            {errors.lastName && (
              <Text role="alert" size={'1'} color="red">
                {errors.lastName.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mt={'3'}
                  mb={'1'}
                >
                  What type of company do you work for?
                </Text>
              </TextField.Slot>
              <Controller
                name="company_type"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Company type field is required',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select.Root value={value} onValueChange={onChange}>
                    <Select.Trigger
                      placeholder="Select company type"
                      style={{ width: '100%' }}
                    />
                    <Select.Content position="popper">
                      <Select.Item value="Startup">Startup</Select.Item>
                      <Select.Item value="Print-on-Demand Store">
                        Print-on-Demand Store
                      </Select.Item>
                      <Select.Item value="Print-on-Demand Marketplace">
                        Print-on-Demand Marketplace
                      </Select.Item>
                      <Select.Item value="Printing Company">
                        Printing Company
                      </Select.Item>
                      <Select.Item value="E-commerce Platform">
                        E-commerce Platform
                      </Select.Item>
                      <Select.Item value="Development Company">
                        Development Company
                      </Select.Item>
                      <Select.Item value="Marketing Agency">
                        Marketing Agency
                      </Select.Item>
                      <Select.Item value="Other">Other</Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </label>
            {errors.company_type && (
              <Text role="alert" size={'1'} color="red">
                {errors.company_type.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  What’s the size of your company?
                </Text>
              </TextField.Slot>
              <Controller
                name="company_size"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Company size field is required',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select.Root value={value} onValueChange={onChange}>
                    <Select.Trigger
                      placeholder="Select company size"
                      style={{ width: '100%' }}
                    />
                    <Select.Content position="popper">
                      <Select.Item value="1">Only me</Select.Item>
                      <Select.Item value="2-10">2-10</Select.Item>
                      <Select.Item value="11-50">11-50</Select.Item>
                      <Select.Item value="51-200">51-200</Select.Item>
                      <Select.Item value="201-500">201-500</Select.Item>
                      <Select.Item value="501-1000">501-1000</Select.Item>
                      <Select.Item value="1001+">1001+</Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </label>
            {errors.company_size && (
              <Text role="alert" size={'1'} color="red">
                {errors.company_size.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  What’s your role?
                </Text>
              </TextField.Slot>
              <Controller
                name="job_title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Job title field is required',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select.Root value={value} onValueChange={onChange}>
                    <Select.Trigger
                      placeholder="Select job title"
                      style={{ width: '100%' }}
                    />
                    <Select.Content position="popper">
                      <Select.Item value="Founder">Founder </Select.Item>
                      <Select.Item value="Executive">Executive</Select.Item>
                      <Select.Item value="Designer">Designer</Select.Item>
                      <Select.Item value="Engineer">Engineer</Select.Item>
                      <Select.Item value="Product Manager">
                        Product Manager
                      </Select.Item>
                      <Select.Item value="Partnership Manager">
                        Partnership Manager
                      </Select.Item>
                      <Select.Item value="Innovation Manager">
                        Innovation Manager
                      </Select.Item>
                      <Select.Item value="Sales">Sales</Select.Item>
                      <Select.Item value="Marketer">Marketer</Select.Item>
                      <Select.Item value="Other">Other</Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </label>
            {errors.job_title && (
              <Text role="alert" size={'1'} color="red">
                {errors.job_title.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  Estimated number of images per year
                </Text>
              </TextField.Slot>
              <TextField.Root
                type="number"
                placeholder="100,000"
                {...register('imagesPerYear', {
                  required: {
                    value: true,
                    message:
                      'Estimated number of images per year field is required',
                  },
                })}
              />
            </label>
            {errors.imagesPerYear && (
              <Text role="alert" size={'1'} color="red">
                {errors.imagesPerYear.message}
              </Text>
            )}
            <label>
              <TextField.Slot>
                <Text
                  weight={'medium'}
                  color="gray"
                  size={'1'}
                  mb={'1'}
                  mt={'3'}
                >
                  What will be your main use?
                </Text>
              </TextField.Slot>

              <Controller
                name="secondary_use_cases"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'At least one use case is required',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <CheckboxCards.Root value={value} onValueChange={onChange}>
                    <CheckboxCards.Item value="Photoshop Mockups">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          🖥️ Photoshop Mockups
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                    <CheckboxCards.Item value="PoD Product Personalization">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          👕 PoD Product Personalization
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                    <CheckboxCards.Item value="Low-code Automation">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          ⚡️ Low-code Automation
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                    <CheckboxCards.Item value="Product Image Testing">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          🧪 Product Image Testing
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                    <CheckboxCards.Item value="AI Design Automation">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          ✨ AI Design Automation
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                    <CheckboxCards.Item value="Marketing & Branding">
                      <Flex direction="column" width="100%">
                        <Text size={'2'} weight="medium">
                          📢 Marketing & Branding
                        </Text>
                      </Flex>
                    </CheckboxCards.Item>
                  </CheckboxCards.Root>
                )}
              />
            </label>
            {errors.secondary_use_cases && (
              <Text role="alert" size={'1'} color="red">
                {errors.secondary_use_cases.message}
              </Text>
            )}
          </Flex>
          <Flex justify={'end'}>
            <Button type="submit">Submit</Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};
