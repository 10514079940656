import './view-wrapper.scss';
import { Badge, Button, Flex, Text, Tooltip } from '@radix-ui/themes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PublicCatalog, SubscriptionType } from '@/services/types';
import { psdEngineApi } from '@/services/psdEngine';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface ISingleTemplate {
  catalog: PublicCatalog;
  subscriptionTypes: SubscriptionType[];
}

export const SingleTemplate = ({
  catalog,
  subscriptionTypes,
}: ISingleTemplate) => {
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const { psdCategories } = useAppSelector(
    (state) => state.psdManagementReducer
  );

  // const [loadMockup, { isLoading }] =
  //   psdEngineApi.useLoadPublicPsdMockupMutation();

  return (
    <Link
      className="item-link"
      to={{
        pathname: `/mockup/${catalog.id}`,
      }}
      state={{ from: location.pathname }}
    >
      {' '}
      <Flex className="single-template zoom-animate-image" align={'end'}>
        <LazyLoadImage
          alt={`${catalog.name}`}
          className=""
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            objectPosition: 'center',
          }}
          effect="blur"
          src={catalog.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'}
          delayTime={0}
          placeholderSrc={
            catalog.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'
          }
          wrapperProps={{
            style: { transitionDelay: '0s' },
          }}
        />

        <Flex className="head-wrapper" align={'center'} p={'4'} gap={'2'}>
          {/* {subscriptionTypes.find((st) => st.id === catalog.subscription_type_id)
          ?.name === 'Pro' && (
          <Flex width={'20px'}>
            <ProCrownIcon width="18px" height="18px" />
          </Flex>
        )} */}
          {catalog.is_ai_mockup ? (
            <Badge className="badge" title={'✨ AI Backgrounds'} size={'2'}>
              ✨ AI Backgrounds
            </Badge>
          ) : (
            <></>
          )}
        </Flex>
        <Flex className="text-wrapper" align={'end'} p={'4'}>
          {/* <Button style={{ width: '100%' }}>Use Mockup</Button> */}
          <Tooltip content={catalog.name}>
            <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
              {catalog.name}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
    </Link>
  );
};
