import Uppy, {
  DefaultPluginOptions,
  SuccessResponse,
  UIPlugin,
  UppyFile,
} from '@uppy/core';

class PostProcessPlugin extends UIPlugin {
  constructor(
    uppy: Uppy<Record<string, unknown>, Record<string, unknown>>,
    opts: DefaultPluginOptions
  ) {
    super(uppy, opts);
    this.id = opts.id || 'PostProcessPlugin';
    this.type = 'progressindicator';

    // Add listeners
    this.uppy.on('upload-success', (file, response) => {
      if (file && response) {
        this.postProcess(file, response);
      }
    });
    this.uppy.on('upload-error', (file, error, response) => {
      console.log('error with file:', file?.id);
      console.log('error message:', error);
    });
  }

  render(state: any) {
    // Return an empty div as this plugin doesn't render anything visible
    return document.createElement('div');
  }

  async postProcess(
    file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
    response: SuccessResponse
  ) {
    this.uppy.emit('preprocess-progress', file, {
      mode: 'indeterminate',
      message: 'Processing file...',
    });

    // Make API call for preprocess-progress
    await fetch(`${API_BASE_URL}psd/process`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ psd_id: response?.body?.data?.psd_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.uppy.emit('preprocess-complete', file);
          this.uppy.emit('postprocess-complete', {
            type: 'preprocess-complete',
            message: {
              psdId: data.data.psd_id,
              mockupId: data.data.mockup_id,
            },
          });
        } else {
          this.uppy.emit('error', file);
          this.uppy.emit('postprocess-complete', {
            type: 'error',
            message: data.message,
          });
        }
      })
      .catch((error) => {
        this.uppy.emit('error', file);
        this.uppy.emit('postprocess-complete', {
          type: 'error',
          message: error.message,
        });
      });
  }

  install() {
    // No need for installation as this plugin doesn't render anything
  }

  uninstall() {
    // No need for uninstallation as this plugin doesn't render anything
  }
}

// Export the plugin class
export default PostProcessPlugin as typeof UIPlugin;
