import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './mockup-catalog.scss';
import { Box, Flex, Tabs, Text } from '@radix-ui/themes';
import { useState } from 'react';
import { psdManagementApi } from '@/services/psdManagement';

export const MockupCatalog = () => {
  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();

  const [tabValue, setTabValue] = useState<string>(
    psdCategories.data?.data?.[0]?.id.toString() || '1'
  );

  const onTabChange = (id: string) => {
    const categories = psdCategories.data?.data;
    if (categories) {
      setTabValue(id.toString());
    }
  };

  return (
    <Flex
      className="view-wrapper-mockup-catalog"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | Public Library</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex className="header" mt={'5'} mx={'5'} align={'center'}>
        <Text weight={'bold'} size={'5'}>
          Public Library
        </Text>
      </Flex>
      <Tabs.Root value={tabValue} onValueChange={(val) => onTabChange(val)}>
        <Box px={'6'}>
          <Tabs.List>
            {psdCategories.data?.data
              ?.filter((item) => !item.is_default && item.is_visible)
              ?.map((category) => (
                <Tabs.Trigger key={category.id} value={category.id.toString()}>
                  {category.name} ({category.count})
                </Tabs.Trigger>
              ))}
          </Tabs.List>
        </Box>

        <Box mt={'4'}>
          {psdCategories.data?.data?.map((category) => (
            <Tabs.Content key={category.id} value={category.id.toString()}>
              <ViewWrapper categoryId={category.id} />
            </Tabs.Content>
          ))}
        </Box>
      </Tabs.Root>
    </Flex>
  );
};
