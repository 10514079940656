import './editor.scss';
import { Box, Button, Flex, ScrollArea } from '@radix-ui/themes';
import { ColorCombinations } from './components/ColorCombinations';
import { DesignAssets } from './components/DesignAssets';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ColorVariant } from './components/ColorVariant';
import { DesignAsset } from './components/DesignAsset';
import Skeleton from 'react-loading-skeleton';
import { psdEngineApi } from '@/services/psdEngine';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  setColorPreviewVariation,
  setIsMockupLoading,
} from '@/redux/slices/collection';
import { SmartObjects } from '../smart-objects/SmartObjects';
import { workspaceApi } from '@/services/workspace';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ColorCombinationsIcon } from '@/assets/icons/color-combinations.svg';
import { ReactComponent as DesignAssetsIcon } from '@/assets/icons/design-assets.svg';
import { toast } from 'react-toastify';
import { SmartObject } from '@/services/types';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { AiBackgrounds } from './components/AiBackgrounds';
import { ReactComponent as AiBackgroundIcon } from '@/assets/icons/ai-background.svg';
import { AiBackground } from './components/AiBackground';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IEditor {}

export const Editor = ({}: IEditor) => {
  const { collectionId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const { activeSmartObject, designAssets, colorCombinations, smartObjects } =
    useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const canUseAiBackgrounds = useFeatureFlagEnabled('ai-backgrounds');

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  const getCrrentMockup = psdEngineApi.useGetCurrentMockupQuery(
    {
      mockup_id: parseInt(
        geyMyProjects.data?.data
          .find((project) => project.uuid === collectionId)
          ?.id.toString()!
      ),
    },
    {
      skip: !geyMyProjects.data?.data
        .find((project) => project.uuid === collectionId)
        ?.id.toString(),
      refetchOnMountOrArgChange: true,
    }
  );
  const getSmartObjects = psdEngineApi.useGetMockupSmartObjectsQuery(
    {
      mockup_id: parseInt(
        geyMyProjects.data?.data
          .find((project) => project.uuid === collectionId)
          ?.id.toString()!
      ),
    },
    {
      skip:
        !getCrrentMockup.data?.data ||
        !geyMyProjects.data?.data
          .find((project) => project.uuid === collectionId)
          ?.id.toString(),
      refetchOnMountOrArgChange: true,
    }
  );
  const getAssets = psdEngineApi.useGetMockupSmartObjectAssetsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject || designAssets?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );
  const getColors = psdEngineApi.useGetMockupSmartObjectColorCombinationsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject ||
        colorCombinations?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );

  const [loadMockup, { isLoading: isLoadingMockup, status }] =
    psdEngineApi.useLoadPublicPsdMockupMutation();
  const isParsableNumber = (value: any) => {
    // Check if the value is a string
    if (typeof value !== 'string') {
      return false;
    }

    // Convert the string to a number
    const number = Number(value);

    // Check if the result is a finite number
    return Number.isFinite(number);
  };

  const [soData, setSoData] = useState<{
    data: SmartObject[];
    isLoading: boolean;
  }>({ data: [], isLoading: false });

  useEffect(() => {
    async function createBulkTemplate() {
      try {
        const data = await loadMockup({
          psd_id: parseInt(collectionId as string),
        }).unwrap();

        await dispatch(
          psdEngineApi.endpoints.getCurrentMockup.initiate({
            mockup_id: data.data.id,
          })
        ).unwrap();
        if (!activeSmartObject && !smartObjects) {
          setSoData({ data: [], isLoading: true });
          const soFetchData = await dispatch(
            psdEngineApi.endpoints.getMockupSmartObjects.initiate(
              {
                mockup_id: data.data.id,
              },
              { forceRefetch: true }
            )
          ).unwrap();
          setSoData({ data: soFetchData.data, isLoading: false });
        }
        await geyMyProjects.refetch();

        let fromLocation: 'public' | 'private' | 'website' = 'website';
        switch (location.state?.from) {
          case '/mockup-library':
            fromLocation = 'public';
            break;
          case '/custom-mockups':
            fromLocation = 'private';
            break;
          default:
            fromLocation = 'website';
        }

        captureEvent('Mockup Opened', {
          mockup_id: data.data.id,
          mockup_name: data.data.name,
          mockup_category_id: data.data?.psdCategory?.id,
          mockup_category_name: data.data?.psdCategory?.name,
          library: fromLocation,
        });

        navigate(`/mockup/${data.data.uuid}`);
        dispatch(setIsMockupLoading(false));
      } catch (error: any) {
        console.log(error);
        toast.error(error.data.message, {
          toastId: 'open_template_error',
          position: 'bottom-right',
          autoClose: 3000,
        });
      }
    }

    if (
      status !== 'fulfilled' &&
      !isLoadingMockup &&
      isParsableNumber(collectionId) &&
      geyMyProjects.data &&
      !geyMyProjects.data?.data
        .find((project) => project.uuid === collectionId)
        ?.id.toString()
    ) {
      createBulkTemplate();
    }
  }, [geyMyProjects.data?.data]);

  useEffect(() => {
    if (activeView === 'preview') {
      dispatch(setColorPreviewVariation(null));
    }
  }, [activeSmartObject]);

  const [activePreview, setActivePreview] = useState<
    'colors' | 'assets' | 'ai-backgrounds'
  >('colors');

  return (
    <Box className="editor-wrapper-scroll">
      <Flex
        className="editor-wrapper"
        direction={'column'}
        align={'start'}
        gap={'4'}
        style={{
          paddingTop: activeView === 'preview' && isMobile ? '29px' : '0',
          borderTop:
            activeView === 'preview' && isMobile ? '1px solid #dfe3e6' : 'none',
        }}
      >
        {activeView === 'preview' && (
          <>
            <SmartObjects
              smartObjectsData={getSmartObjects.data?.data || soData.data || []}
              isLoadingSmartObjects={
                getSmartObjects.isLoading || soData.isLoading
              }
            />
            {isMobile &&
            canUseAiBackgrounds &&
            activePreview === 'ai-backgrounds' ? (
              !getAssets.isLoading && activeSmartObject ? (
                activeSmartObject?.is_background_layer ? (
                  <AiBackgrounds />
                ) : (
                  <></>
                )
              ) : (
                <Flex direction={'column'} align={'start'} gap={'4'} px={'4'}>
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(4)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'76px'}
                        width={'76px'}
                        style={{
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              )
            ) : (
              !isMobile &&
              canUseAiBackgrounds &&
              (!getAssets.isLoading && activeSmartObject ? (
                activeSmartObject?.is_background_layer ? (
                  <AiBackgrounds />
                ) : (
                  <></>
                )
              ) : (
                <Flex direction={'column'} align={'start'} gap={'4'} px={'4'}>
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(4)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'76px'}
                        width={'76px'}
                        style={{
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              ))
            )}
            {!activeSmartObject?.is_background_layer &&
            isMobile &&
            activePreview === 'colors' ? (
              !getColors.isLoading && activeSmartObject ? (
                <ColorCombinations />
              ) : (
                <Flex
                  direction={'column'}
                  align={'start'}
                  gap={'4'}
                  pt={'4'}
                  px={'4'}
                >
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(7)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'40px'}
                        width={'40px'}
                        style={{
                          borderRadius: '40px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              )
            ) : (
              !activeSmartObject?.is_background_layer &&
              !isMobile &&
              (!getColors.isLoading && activeSmartObject ? (
                <ColorCombinations />
              ) : (
                <Flex
                  direction={'column'}
                  align={'start'}
                  gap={'4'}
                  pt={'4'}
                  px={'4'}
                >
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(7)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'40px'}
                        width={'40px'}
                        style={{
                          borderRadius: '40px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              ))
            )}

            {!activeSmartObject?.is_background_layer &&
            isMobile &&
            activePreview === 'assets' ? (
              !getAssets.isLoading && activeSmartObject ? (
                <DesignAssets />
              ) : (
                <Flex direction={'column'} align={'start'} gap={'4'} px={'4'}>
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(4)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'76px'}
                        width={'76px'}
                        style={{
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              )
            ) : (
              !activeSmartObject?.is_background_layer &&
              !isMobile &&
              (!getAssets.isLoading && activeSmartObject ? (
                <DesignAssets />
              ) : (
                <Flex direction={'column'} align={'start'} gap={'4'} px={'4'}>
                  <Box>
                    <Skeleton
                      height={'44px'}
                      width={'100%'}
                      style={{
                        minWidth: '325px',
                      }}
                    />
                  </Box>
                  <Flex style={{ gap: '7px' }}>
                    {[...Array(4)].map((_, index) => (
                      <Skeleton
                        key={index}
                        height={'76px'}
                        width={'76px'}
                        style={{
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </Flex>
                </Flex>
              ))
            )}
            {isMobile && (
              <Flex
                gap={'2'}
                align={'center'}
                justify={'center'}
                style={{ width: '100%' }}
              >
                {(activePreview === 'assets' ||
                  activePreview === 'ai-backgrounds') && (
                  <Button
                    variant="ghost"
                    color="gray"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                    onClick={() => setActivePreview('colors')}
                  >
                    <ColorCombinationsIcon className="icon" />
                  </Button>
                )}
                {activePreview === 'colors' && (
                  <Button
                    variant="solid"
                    color="blue"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                  >
                    <ColorCombinationsIcon className="icon white" />
                  </Button>
                )}
                {(activePreview === 'colors' ||
                  activePreview === 'ai-backgrounds') && (
                  <Button
                    variant="ghost"
                    color="gray"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                    onClick={() => setActivePreview('assets')}
                  >
                    <DesignAssetsIcon className="icon" />
                  </Button>
                )}
                {activePreview === 'assets' && (
                  <Button
                    variant="solid"
                    color="blue"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                  >
                    <DesignAssetsIcon className="icon white" />
                  </Button>
                )}

                {(activePreview === 'colors' || activePreview === 'assets') && (
                  <Button
                    variant="ghost"
                    color="gray"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                    onClick={() => setActivePreview('ai-backgrounds')}
                  >
                    <AiBackgroundIcon className="icon" />
                  </Button>
                )}

                {activePreview === 'ai-backgrounds' && (
                  <Button
                    variant="solid"
                    color="blue"
                    size={'2'}
                    style={{
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      padding: '8px',
                    }}
                  >
                    <AiBackgroundIcon className="icon white" />
                  </Button>
                )}
              </Flex>
            )}
          </>
        )}

        {activeView === 'update-color' && <ColorVariant />}
        {activeView === 'update-asset' && <DesignAsset />}
        {activeView === 'create-ai-background' && <AiBackground />}
      </Flex>
    </Box>
  );
};
