import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  getFilteredRowModel,
  ColumnFiltersState,
  Column,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import './table.scss';
import { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Link,
  ScrollArea,
  Text,
} from '@radix-ui/themes';
import { DebouncedInput } from './DebouncedInput';
import { ReactComponent as GalleryAddIcon } from '@/assets/icons/gallery-add-linear.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { shopsProductsApi } from '@/services/shopsProducts';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Listing } from '@/services/types';
import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';
import { PlusIcon } from '@radix-ui/react-icons';
import { ReactComponent as BagIcon } from '@/assets/icons/bag-4-linear.svg';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';

export const ProductsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();
  const captureEvent = useCaptureEvent();
  const { listings, isSyncingListings } = useAppSelector(
    (state) => state.shopsProductsReducer
  );
  const { activeIntegrations, activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const [columnVisibility, setColumnVisibility] = useState<{
    [key in keyof Listing]: any;
  }>({
    listing_id: false,
    sku: false,
    title: true,
    price: true,
    state: true,
    listing_url: false,
    image_thumbnail: true,
    created_at_timestamp: false,
    created_at: true,
    views: true,
    type: true,
  });

  const columns = useMemo<ColumnDef<Listing>[]>(
    () => [
      {
        accessorKey: 'sku',
      },
      {
        accessorKey: 'image_thumbnail',
        header: '',
        cell: ({ row }) => (
          <Avatar src={row.original.image_thumbnail || ''} fallback="P" />
        ),
        size: 50,
        enableSorting: false,
      },
      {
        accessorKey: 'title',
        header: () => 'Product',
        cell: ({ row }) => (
          <Flex direction={'column'} className="product-wrapper">
            <Link
              className="product-name"
              href={row.original.listing_url}
              target="_blank"
            >
              <Text>{row.original.title}</Text>
            </Link>
            {/* {row.original.state === 'active' ? (
              <Link
                className="product-name"
                href={row.original.listing_url}
                target="_blank"
              >
                <Text>{row.original.title}</Text>
              </Link>
            ) : (
              <Text className="product-name">{row.original.title}</Text>
            )} */}
            {row.original.sku && (
              <Text className="sku">SKU: {row.original.sku}</Text>
            )}
          </Flex>
        ),
        size: 400,
        enableSorting: false,
      },
      {
        accessorKey: 'state',
        header: () => 'Status',
        cell: ({ row }) => {
          const state =
            row.original.state.charAt(0).toUpperCase() +
            row.original.state.slice(1);

          return row.original.state === 'active' ? (
            <Badge color="green">{state}</Badge>
          ) : (
            <Badge>{state}</Badge>
          );
        },
        size: 110,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'type',
        header: () => 'Type',
        size: 110,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'views',
        header: () => 'Views',
        size: 110,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: true,
      },
      {
        accessorKey: 'price',
        header: () => 'Price',
        size: 110,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'created_at',
        header: 'Published',
        size: 110,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'mockups',
        header: () => 'Mockups',
        cell: ({ row }) => (
          <Button
            color="gray"
            variant="outline"
            onClick={() => {
              captureEvent('Add Mockups Initiated', {
                type: 'Etsy',
                shop_name: activeIntegrationDetails?.shop_name,
                shop_url: activeIntegrationDetails?.shop_url,
                product_info: row.original,
              });
              navigate(
                `/shop-products/${shopId}/product/${row.original.listing_id}`
              );
            }}
          >
            <GalleryAddIcon className="icon black" />
            <Text size="1" weight="medium">
              Add Mockups
            </Text>
          </Button>
        ),
        size: 140,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  const [productState, setProductState] = useState([
    { name: 'All', slug: 'all', value: '', selected: true },
    { name: 'Active', slug: 'active', value: 'active', selected: false },
    { name: 'Draft', slug: 'draft', value: 'draft', selected: false },
  ]);

  const [data, setData] = useState(() => listings.data.products);
  const [isConnectingShop, setIsConnectingShop] = useState(false);

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [stateFilter, setStateFilter] = useState<Column<Listing, unknown>>();
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    setData(listings.data.products);
  }, [listings.data.products]);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const syncProductListingsAction = async () => {
    captureEvent('Product Listing Fetch Initiated', {
      type: 'Etsy',
      shop_name: activeIntegrationDetails?.shop_name,
      shop_url: activeIntegrationDetails?.shop_url,
    });
    try {
      await dispatch(
        shopsProductsApi.endpoints.syncListings.initiate({
          shop_integration_platform_id: parseInt(shopId as string),
        })
      ).unwrap();
      await dispatch(
        shopsProductsApi.endpoints.getListings.initiate(
          {
            shop_integration_platform_id: parseInt(shopId as string),
          },
          { forceRefetch: true }
        )
      ).unwrap();
      captureEvent('Product Listing Fetch Successful', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        products_count: listings.data.products?.length,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const connectShop = async () => {
    setIsConnectingShop(true);
    const url = await dispatch(
      shopsIntegrationsApi.endpoints.getAutorizationUrl.initiate({
        id: parseInt(shopId as string),
      })
    ).unwrap();
    captureEvent('Shop connect innitiated', {
      type: 'Etsy',
    });
    window.location.replace(url.data.authorization_url);
    setIsConnectingShop(false);
  };

  useEffect(() => {
    if (activeIntegrations.length !== 0 && !listings.data.lastSync) {
      syncProductListingsAction();
    }
  }, [activeIntegrations]);

  return (
    <Flex align={'center'} justify={'center'} height={'100%'}>
      {listings.data.lastSync && !listings.isLoading ? (
        <Box className="products-tabe-wrapper">
          <Flex
            className="pre-table-header"
            align={'center'}
            justify={'between'}
          >
            <Flex align={'center'} gap={'2'}>
              {productState.map((state) => (
                <Button
                  key={state.slug}
                  variant={state.selected ? 'soft' : 'ghost'}
                  color="gray"
                  value={stateFilter?.getFilterValue()?.toString()}
                  onClick={() => {
                    stateFilter?.setFilterValue(state.value);

                    setProductState((old) =>
                      old.map((oldState) =>
                        oldState.slug === state.slug
                          ? {
                              ...oldState,
                              selected: true,
                            }
                          : {
                              ...oldState,
                              selected: false,
                            }
                      )
                    );
                  }}
                >
                  {state.name}
                </Button>
              ))}
            </Flex>
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => {
                setGlobalFilter(String(value));
              }}
              placeholder="Search by SKU or Title"
            />
          </Flex>
          <ScrollArea
            type="hover"
            scrollbars="vertical"
            style={{ height: 'calc(100vh - 157px)' }}
          >
            <table className="w-full ">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      if (!stateFilter && header.column.id === 'state') {
                        setStateFilter(header.column);
                      }
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: `${header.getSize()}px` }}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              className={
                                header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : ''
                              }
                              onClick={header.column.getToggleSortingHandler()}
                              title={
                                header.column.getCanSort()
                                  ? header.column.getNextSortingOrder() ===
                                    'asc'
                                    ? 'Sort ascending'
                                    : header.column.getNextSortingOrder() ===
                                      'desc'
                                    ? 'Sort descending'
                                    : 'Clear sort'
                                  : undefined
                              }
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <ArrowUpIcon />,
                                desc: <ArrowDownIcon />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ScrollArea>
        </Box>
      ) : activeIntegrations.length === 0 ? (
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          gap={'4'}
          className="empty-page-state"
        >
          <Flex align={'center'} justify={'center'} className="cercle-wrap">
            <BagIcon className="svg-icon" />
          </Flex>
          <Text size="2" weight="regular" align={'center'}>
            Connect your Etsy Shop to automatically sync Mockups with your
            Products
          </Text>

          <Button
            variant="solid"
            onClick={() => {
              if (isAppPro) {
                connectShop();
              } else {
                dispatch(setIsAppPaymentPopupOpen(true));
              }
            }}
            loading={isConnectingShop}
          >
            <PlusIcon className="icon white" />
            <Text size="1" weight="medium">
              Connect Etsy Shop
            </Text>
          </Button>
        </Flex>
      ) : (
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          gap={'4'}
          className="empty-page-state"
        >
          <Flex align={'center'} justify={'center'} className="cercle-wrap">
            <BagIcon className="svg-icon" />
          </Flex>
          <Text size="2" weight="regular" align={'center'}>
            Syncing Product Listings from Etsy
          </Text>

          <Button
            variant="solid"
            onClick={() => syncProductListingsAction()}
            loading={listings.isLoading || isSyncingListings}
          >
            <Text size="1" weight="medium">
              Fetch Product Listings
            </Text>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
