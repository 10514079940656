import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { UserRenders } from '@/redux/slices/types';
import { DataModelArray } from './types';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserRenders: builder.query<DataModelArray<UserRenders>, void>({
      query: () => `user_renders`,
    }),
    getCheckoutSession: builder.query<any, { checkoutSessionId: string }>({
      query: ({ checkoutSessionId }) =>
        `stripe/checkout/session/${checkoutSessionId}`,
    }),
    getCheckoutSessionURL: builder.mutation<
      any,
      { stripe_price_id: string; subscription_plan_type: 'app' | 'api' }
    >({
      query: (payload) => ({
        url: `stripe/checkout/session`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTopUpRendersCheckoutSessionURL: builder.mutation<
      any,
      {
        renders: number;
      }
    >({
      query: (payload) => ({
        url: `stripe/checkout/session/top-up`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = paymentsApi;
