import { Box, Flex } from '@radix-ui/themes';
import '../view-wrapper.scss';
import { useAppSelector } from '@/hooks';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ReactComponent as Loader } from '@/assets/loader.svg';
import { MainImagePreview } from './MainImagePreview';
import { useMediaQuery } from 'react-responsive';

interface ISinglePreview {}

export const SinglePreview = ({}: ISinglePreview) => {
  const { colorPreviewVariation, colorPreviewVariationIsFetching } =
    useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <Flex className="single-color-preview" align={'center'} justify={'center'}>
      <MainImagePreview
        image={colorPreviewVariation}
        isFetching={colorPreviewVariationIsFetching}
        height={'80vh'}
        isVariationPreview={false}
      />
    </Flex>
  );
};
