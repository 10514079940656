import { useCallback } from 'react';
import posthog, { CaptureOptions, CaptureResult, Properties } from 'posthog-js';

type CaptureEventHook = () => (
  event_name: string,
  properties?: Properties | null | undefined,
  options?: CaptureOptions | undefined
) => void | CaptureResult;

const useCaptureEvent: CaptureEventHook = () => {
  return useCallback(
    (
      event_name: string,
      properties?: Properties | null,
      options: CaptureOptions = { send_instantly: true }
    ) => {
      posthog.capture(event_name, properties, options);
    },
    []
  );
};

export default useCaptureEvent;
