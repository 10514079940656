import { createSlice } from '@reduxjs/toolkit';

interface IAppState {
  themeMode: 'inherit' | 'light' | 'dark' | undefined;
  isAppPaymentPopupOpen: boolean;
}

const userPrefferedThemeMode = window.localStorage.getItem('app-theme');
const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
  ? 'dark'
  : 'light';

const initialState: IAppState = {
  themeMode: (userPrefferedThemeMode ||
    systemTheme ||
    'light') as IAppState['themeMode'],
  isAppPaymentPopupOpen: false,
};

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    setThemeMode(state, { payload }) {
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark', 'light-theme', 'dark-theme');
      state.themeMode = payload;
      root.classList.add(payload);
    },
    setIsAppPaymentPopupOpen(state, { payload }) {
      state.isAppPaymentPopupOpen = payload;
    },
    resetCanvasState: () => initialState,
  },
});

export default appSlice.reducer;

export const { setThemeMode, resetCanvasState, setIsAppPaymentPopupOpen } =
  appSlice.actions;
