import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { routes } from './index';

export const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  if (isAuthenticated) {
    const toPath = sessionStorage.getItem('beforeAuthPath') || routes.APP;
    sessionStorage.removeItem('beforeAuthPath');
    return <Navigate to={toPath} state={{ from: location }} />;
  }

  return children;
};
