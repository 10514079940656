import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  ColorCombination,
  DataModel,
  DataModelArray,
  Mockup,
  PrivatePsd,
  PrivatePhotoshopFile,
} from './types';

export interface ColorSetColor {
  hex_code: string;
  id: number;
  name: string;
}

export interface ColorSet {
  collections: any[];
  color_set_colors: ColorSetColor[];
  id: number;
  name: string;
  visibility: 'all' | 'collection';
}

export const colorsApi = createApi({
  reducerPath: 'colorsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getColorSets: builder.query<DataModelArray<ColorSet>, void>({
      query: () => `color-sets`,
    }),
    createColorSet: builder.mutation<DataModelArray<any>, { name: string }>({
      query: (payload) => ({
        url: `color-sets`,
        method: 'POST',
        body: payload,
      }),
    }),
    renameColorSet: builder.mutation<
      DataModel<ColorCombination>,
      Partial<ColorCombination>
    >({
      query: (payload) => ({
        url: `color-sets/${payload.id}/rename`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteColorSet: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `color-sets/${id}`,
        method: 'DELETE',
      }),
    }),
    addColor: builder.mutation<
      DataModelArray<any>,
      { color_set_id: number; name: string; hex_code: string }
    >({
      query: (payload) => ({
        url: `color-set-colors`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateColor: builder.mutation<DataModel<ColorSetColor>, ColorSetColor>({
      query: (payload) => ({
        url: `color-set-colors/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addToMockupCollection: builder.mutation<
      DataModel<any>,
      { color_set_id: number; mockup_collections: number[] }
    >({
      query: (payload) => ({
        url: `color-sets/mockup-collections/add`,
        method: 'POST',
        body: payload,
      }),
    }),
    removeFromMockupCollection: builder.mutation<
      DataModel<any>,
      {
        color_set_id: number;
        mockup_collections: number[];
        activeMockupCollectionId: number;
      }
    >({
      query: (payload) => ({
        url: `color-sets/mockup-collections/remove`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteColorSetColor: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `color-set-colors/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {} = colorsApi;
