import { Flex, Box } from '@radix-ui/themes';
import '../view-wrapper.scss';
import '../components/youtubeVideo.scss';
import { useAppSelector } from '@/hooks';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MainImagePreview } from './MainImagePreview';
import { useEffect, useRef } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import AiVideThumb from '@/assets/ai-video-thumb.webp';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IAiBackgroundPreview {}

export const AiBackgroundPreview = ({}: IAiBackgroundPreview) => {
  const { mockup, activeVariation } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { aiBackgroundImage, isGeneratingPreviewImage, isImageGenerated } =
    useAppSelector((state) => state.aiBackgroundsReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const haveTrialProSubscription = useFeatureFlagEnabled(
    'trial-pro-subscription'
  );
  const captureEvent = useCaptureEvent();

  const videoModal = useRef<HTMLDivElement | null>(null);
  const openVideoModalBtn = useRef<HTMLImageElement | null>(null);
  const closeVideoModalBtn = useRef<HTMLButtonElement | null>(null);
  const youtubeVideo = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const modal = videoModal.current;
    const openModalBtn = openVideoModalBtn.current;
    const closeModalBtn = closeVideoModalBtn.current;
    const video = youtubeVideo.current;

    const openModal = () => {
      const videoUrl =
        'https://www.youtube.com/embed/WfHUSTlfNrA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1&fs=1&cc_load_policy=1';

      if (modal && video) {
        modal.style.display = 'block';
        video.src = videoUrl;
        captureEvent('AI Youtube video modal opened.');
        captureEvent('AI Youtube video played.');
      }
    };

    const closeModal = () => {
      if (modal && video) {
        modal.style.display = 'none';
        video.src = ''; // Stop the video when the modal is closed
        captureEvent('AI Youtube video modal closed.');
      }
    };

    const clickOutsideModal = (event: MouseEvent) => {
      if (modal && event.target === modal && video) {
        modal.style.display = 'none';
        video.src = ''; // Stop the video when the modal is closed
        captureEvent('AI Youtube video modal closed.');
      }
    };

    if (openModalBtn && closeModalBtn) {
      openModalBtn.addEventListener('click', openModal);
      closeModalBtn.addEventListener('click', closeModal);
      window.addEventListener('click', clickOutsideModal);
    }

    return () => {
      if (openModalBtn && closeModalBtn) {
        openModalBtn.removeEventListener('click', openModal);
        closeModalBtn.removeEventListener('click', closeModal);
        window.removeEventListener('click', clickOutsideModal);
      }
    };
  }, []);

  return (
    <Flex className="single-color-preview" align={'center'} justify={'center'}>
      {haveTrialProSubscription || currentUser?.subscription === 'paid' ? (
        <MainImagePreview
          image={
            (isImageGenerated
              ? aiBackgroundImage
              : activeVariation?.export_path) || mockup?.thumbnail
          }
          isFetching={isGeneratingPreviewImage}
          height={'80vh'}
          isVariationPreview={false}
        />
      ) : (
        <Flex className="main-video-wrapper">
          <Box className="video-thumbnail-wrapper">
            <img ref={openVideoModalBtn} src={AiVideThumb} id="openModal" />
          </Box>

          <Box
            ref={videoModal}
            id="videoModal"
            className="custom-yt-modal"
            display={'none'}
          >
            <Flex className="modal-content">
              <span ref={closeVideoModalBtn} className="close">
                &times;
              </span>
              <iframe
                ref={youtubeVideo}
                id="youtubeVideo"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/WfHUSTlfNrA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1&fs=1&cc_load_policy=1"
                allow="fullscreen;"
              ></iframe>
            </Flex>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
