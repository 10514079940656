import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './mockup-templates.scss';
import { Flex, Text } from '@radix-ui/themes';

export const MockupTemplates = () => {
  return (
    <Flex
      className="view-wrapper-mockup-templates"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | My Mockups</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        mt={'5'}
        mx={'5'}
        align={'center'}
        justify={'between'}
      >
        <Text weight={'bold'} size={'5'}>
          My Mockups
        </Text>
      </Flex>
      <ViewWrapper />
    </Flex>
  );
};
