import { useEffect } from 'react';

type KeyCombination = {
  key: string;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  altKey?: boolean;
  action: () => void;
};

const useKeyPress = (keys: KeyCombination[]) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      keys.forEach((keyCombo) => {
        if (
          e.key === keyCombo.key &&
          (keyCombo.ctrlKey === undefined ||
            e.ctrlKey === keyCombo.ctrlKey ||
            e.metaKey) &&
          (keyCombo.shiftKey === undefined ||
            e.shiftKey === keyCombo.shiftKey) &&
          (keyCombo.altKey === undefined || e.altKey === keyCombo.altKey)
        ) {
          e.preventDefault(); // Always prevent the default behavior
          keyCombo.action();
        }
      });
    };

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [keys]);
};

export default useKeyPress;
