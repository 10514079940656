export const PPColors: string[] = [
  '#0B0B0B',
  '#0C0C0C',
  '#52514F',
  '#CECECC',
  '#E3E3DD',
  '#F0F1EA',
  '#FFFFFF',
  '#212642',
  '#302D4B',
  '#01408D',
  '#536BA7',
  '#447085',
  '#008DB5',
  '#5CA4C5',
  '#668EA7',
  '#BCCBEE',
  '#CFD5E1',
  '#28332E',
  '#223E25',
  '#5B642F',
  '#5C9346',
  '#1A9462',
  '#A5C8BC',
  '#AAD5B9',
  '#B2EBCF',
  '#C3E2E3',
  '#F7FEE5',
  '#2C1013',
  '#721D37',
  '#B90116',
  '#D23D62',
  '#C02773',
  '#BF6E6E',
  '#C2878E',
  '#DEAFCD',
  '#EFBBE3',
  '#FDBFC7',
  '#322622',
  '#3E3C3D',
  '#5F5849',
  '#C85313',
  '#BE672D',
  '#FF6F4E',
  '#ED8043',
  '#EDA027',
  '#FF9E00',
  '#FFD667',
  '#E0CD64',
  '#F3C2B2',
  '#DDB792',
  '#E7D4C0',
  '#E5D9C9',
];
