import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { useState, forwardRef, useImperativeHandle } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ColorSet, colorsApi } from '@/services/colors';

interface IDeleteColorSet {
  colorSet: ColorSet;
}

export const DeleteColorSet = forwardRef(
  ({ colorSet }: IDeleteColorSet, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);

    const [deleteColorSet] = colorsApi.useDeleteColorSetMutation();

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const deleteColorSetAction = async (id: number) => {
      await deleteColorSet({ id });
      dispatch(
        colorsApi.endpoints.getColorSets.initiate(null!, {
          forceRefetch: true,
        })
      );
      captureEvent('Color Set deleted');
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Delete Color Set</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to delete this Color Set?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Cancel>
              <Button
                variant="solid"
                color="red"
                onClick={() => deleteColorSetAction(colorSet.id)}
              >
                Delete
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
