import './sync-images.scss';
import { Box, Button, Flex } from '@radix-ui/themes';
import { ReactComponent as GalleryIcon } from '@/assets/icons/gallery.svg';
import { DragEvent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { ProductImage } from '@/services/types';
import posthog from 'posthog-js';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IEmptySlot {
  image: ProductImage;
  setImage: (data: ProductImage) => void;
  deleteImage: (id: number) => void;
}

export const EmptySlot = ({ image, setImage, deleteImage }: IEmptySlot) => {
  const captureEvent = useCaptureEvent();
  const { activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );
  const { productListing } = useAppSelector(
    (state) => state.shopsProductsReducer
  );

  const [localData, setLocalData] = useState<any>(null);

  const onDropEvent = (event: DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.getData('data')) {
      const data = JSON.parse(event.dataTransfer.getData('data'));
      setImage({
        rank: image.rank,
        image_url: data.image,
        listing_image_id: image.listing_image_id,
        mockup_variation_id: data.variation.id,
      });
      setLocalData(data);
      captureEvent('Image Dropped', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        template_name: data.project.name,
        template_id: data.project.id,
        template_category: data.project.psd_category_id,
        product_info: productListing.data.details,
      });
    } else {
      event.currentTarget.classList.remove('is-dragging');
    }
  };

  const onDragOverEvent = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add('is-dragging');
  };

  const onDragLeaveEvent = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove('is-dragging');
  };

  return image.image_url ? (
    <Box className="slot-image">
      <LazyLoadImage
        draggable={false}
        key={image.rank}
        style={{
          objectFit: 'cover',
          width: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={image.image_url}
        delayTime={0}
        placeholderSrc={image.image_url}
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />
      <Button
        color="red"
        variant="surface"
        size={'1'}
        className="delete-button"
        onClick={() => {
          captureEvent('Image Removed', {
            type: 'Etsy',
            shop_name: activeIntegrationDetails?.shop_name,
            shop_url: activeIntegrationDetails?.shop_url,
            template_name: localData?.project.name,
            template_id: localData?.project.id,
            template_category: localData?.project.psd_category_id,
            product_info: productListing.data.details,
          });
          deleteImage(image.rank);
        }}
      >
        <TrashBin className="icon" width="16px" height="16px" />
      </Button>
    </Box>
  ) : (
    <Flex
      className={`empty-slot`}
      align={'center'}
      justify={'center'}
      onDrop={(event) => onDropEvent(event)}
      onDragOver={(event) => onDragOverEvent(event)}
      onDragLeave={(event) => onDragLeaveEvent(event)}
    >
      <GalleryIcon className="icon black" width={'20px'} height={'20px'} />
    </Flex>
  );
};
