import { Text, Flex, Button, TextArea, Switch, Box } from '@radix-ui/themes';
import '../editor.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { aiBackgroundsApi } from '@/services/aiBackgrounds';
import { useMediaQuery } from 'react-responsive';
import { AiBackgroundPreview } from '../../view-wrapper/components/AiBackgroundPreview';
import {
  resetAiBackgroundsState,
  setCurrentUserPrompt,
  setIsGeneratingPreviewImage,
  setIsImageGenerated,
} from '@/redux/slices/aiBackgrounds';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { toast } from 'react-toastify';
import { userApi } from '@/services/user';

interface IFormInput {
  prompt: string;
  negativePromptEnabled: boolean;
  negativePrompt: string;
}

export const AiBackground = () => {
  const dispatch = useAppDispatch();
  const { mockup, activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const formRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const captureEvent = useCaptureEvent();
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const haveTrialProSubscription = useFeatureFlagEnabled(
    'trial-pro-subscription'
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    getValues,
    reset,
  } = useForm<IFormInput>({
    defaultValues: {
      prompt: '',
      negativePrompt:
        'oversaturated, ugly, 3d, render, cartoon, grain, low-res, kitsch, text, lens, camera',
      negativePromptEnabled: false,
    },
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updateMockupAction(data);

  const updateMockupAction = async (formInput: IFormInput) => {
    if (isAppPro) {
      try {
        setIsGenerating(true);
        dispatch(setIsGeneratingPreviewImage(true));
        const data = {
          mockup_id: mockup.id,
          prompt: formInput.prompt,
          negative_prompt: formInput.negativePrompt,
          smart_object_id: activeSmartObject.id,
        };
        await dispatch(
          aiBackgroundsApi.endpoints.generateAiBackgroundImage.initiate(data, {
            forceRefetch: true,
          })
        ).unwrap();
        dispatch(setIsImageGenerated(true));
        dispatch(setCurrentUserPrompt(formInput.prompt));
        captureEvent('Ai background image successfully generated', {
          prompt: data,
        });
        await dispatch(
          userApi.endpoints.getCurrentUser.initiate(null!, {
            forceRefetch: true,
          })
        ).unwrap();
      } catch (error: any) {
        console.log(error);
        captureEvent('Error generaing Ai background image', { error });
        toast.error(error.data.message, {
          toastId: 'generate-ai-image-error',
          position: 'bottom-right',
          autoClose: 7000,
        });
      } finally {
        setIsGenerating(false);
        dispatch(setIsGeneratingPreviewImage(false));
      }
    } else {
      dispatch(setIsAppPaymentPopupOpen(true));
    }
  };

  useEffect(() => {
    dispatch(setIsImageGenerated(false));
    dispatch(resetAiBackgroundsState());
  }, []);

  const getNumOfCreditsLeft = () => {
    if (currentUser?.subscription === 'free') {
      return false;
    }
    try {
      return (
        parseInt((currentUser?.ai_credits as any)?.total || 0) -
          parseInt((currentUser?.ai_credits as any)?.used || 0) <
        1
      );
    } catch (error) {
      return true;
    }
  };

  return (
    <Flex direction={'column'} gap={'2'} p={'4'} width={'100%'}>
      {isMobile && activeView === 'create-ai-background' && (
        <Box px={'4'}>
          <AiBackgroundPreview />
        </Box>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={formRef}
      >
        <Flex direction="column" gap="4">
          <label>
            <Text as="div" size="1" mb="1" weight="medium">
              Background Prompt
            </Text>
            <TextArea
              size="3"
              resize="vertical"
              style={{ minHeight: '100px' }}
              placeholder="Mountain at sunset"
              {...register('prompt', {
                required: {
                  value: true,
                  message: 'Prompt field is required',
                },
                maxLength: {
                  value: 255,
                  message: 'Maximum Prompt length is 255 characters',
                },
              })}
            />
            {errors.prompt && (
              <Text role="alert" size={'1'} color="red">
                {errors.prompt.message}
              </Text>
            )}
          </label>
          <label>
            <Flex justify={'between'} align={'center'} mb={'1'}>
              <Text as="div" size="1" mb="1" weight="medium">
                Negative Prompt
              </Text>
              <Controller
                name="negativePromptEnabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onCheckedChange={onChange} />
                )}
              />
            </Flex>

            <TextArea
              size="3"
              resize="vertical"
              style={{ minHeight: '100px' }}
              placeholder="oversaturated, ugly, 3d, render, cartoon, grain"
              disabled={!watch().negativePromptEnabled}
              {...register('negativePrompt', {
                required: {
                  value: watch().negativePromptEnabled,
                  message: "Negative Prompt field can't be empty",
                },
                maxLength: {
                  value: 255,
                  message: 'Maximum Negative Prompt length is 255 characters',
                },
              })}
            />
            {errors.negativePrompt && (
              <>
                <Text role="alert" size={'1'} color="red">
                  {errors.negativePrompt.message}
                </Text>
                <br />
              </>
            )}
            <Text
              weight="medium"
              color="gray"
              style={{ lineHeight: 'normal', fontSize: '12px' }}
            >
              Refine your generated background by specifying elements our AI
              won’t include in the final image. For example, write “flowers” if
              you want a scene without any flowers in view.
            </Text>
          </label>
        </Flex>
        <Flex gap="3" mt="4" justify="center">
          <Button
            loading={isGenerating}
            disabled={getValues().prompt === '' || getNumOfCreditsLeft()}
            style={{ width: '100%' }}
          >
            ✨ Generate Scene
          </Button>
        </Flex>
      </form>
      <Flex align={'center'} justify={'center'} mt={'2'}>
        {(haveTrialProSubscription || currentUser?.subscription === 'paid') && (
          <Text
            className="credits-status"
            color="gray"
            weight={'regular'}
            size={'1'}
          >
            {(currentUser?.ai_credits?.total || 0) -
              (currentUser?.ai_credits?.used || 0) || 0}
            /{currentUser?.ai_credits?.total || 25} weekly credits left
            {currentUser?.ai_credits
              ? `, resets on ${currentUser?.ai_credits?.resets_on}`
              : ``}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
