import { createSlice } from '@reduxjs/toolkit';

type activeView =
  | 'preview'
  | 'update-color'
  | 'update-asset'
  | 'create-ai-background';
type activeAction = 'add' | 'edit';

interface IEditorState {
  activeView: activeView;
  activeAction: activeAction;
}

const initialState: IEditorState = {
  activeView: 'preview',
  activeAction: 'add',
};

export const editorSlice = createSlice({
  initialState,
  name: 'editorSlice',
  reducers: {
    resetCanvasState: () => initialState,
    setActiveView(state, { payload }: { payload: activeView }) {
      state.activeView = payload;
    },
    setActiveAction(state, { payload }: { payload: activeAction }) {
      state.activeAction = payload;
    },
    resetEditorState() {
      return initialState;
    },
  },
});

export default editorSlice.reducer;

export const {
  resetCanvasState,
  setActiveView,
  setActiveAction,
  resetEditorState,
} = editorSlice.actions;
