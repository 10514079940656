import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  DataModelArray,
  ShopIntegrationPlatform,
  ActiveIntegrations,
  ActiveIntegrationDetails,
  ShopPlatformsAuthUrl,
  DataModel,
} from './types';

export const shopsIntegrationsApi = createApi({
  reducerPath: 'shopsIntegrationsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getShopIntegrationPlatforms: builder.query<
      DataModelArray<ShopIntegrationPlatform>,
      void
    >({
      query: () => `shop-integration-platform/all`,
    }),
    getActiveIntegrations: builder.query<
      DataModelArray<ActiveIntegrations>,
      void
    >({
      query: () => `shop-integration/get-active-integrations`,
    }),
    getActiveIntegrationDetails: builder.query<
      DataModel<ActiveIntegrationDetails>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `shop-integration/get-active-integration-details?shop_integration_platform_id=${id}`,
        method: 'GET',
      }),
    }),
    disconnnectShopIntegration: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `shop-integration/disconnect`,
        method: 'POST',
        body: { shop_integration_id: id },
      }),
    }),
    getAutorizationUrl: builder.mutation<
      DataModel<ShopPlatformsAuthUrl>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `shop-integration/get-authorization-url`,
        method: 'POST',
        body: { shop_integration_platform_id: id },
      }),
    }),
  }),
});

export const {} = shopsIntegrationsApi;
