import { createSlice } from '@reduxjs/toolkit';
import { Workspace } from './types';
import { workspaceApi } from '@/services/workspace';
import { Project, Design, PrivatePhotoshopFile } from '@/services/types';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { WritableDraft } from 'immer/dist/internal';
import { store } from '../store';

interface IWorkspaceState {
  currentWorkspace: Workspace | null;
  workspaceLoading: boolean;
  myProjects: Project[];
  designs: Design[];
  photoshopFiles: PrivatePhotoshopFile[];
  leftSideMenuWidth: number;
}

const initialState: IWorkspaceState = {
  currentWorkspace: null,
  workspaceLoading: true,
  myProjects: [],
  designs: [],
  photoshopFiles: [],
  leftSideMenuWidth: 0,
};

export const workspaceSlice = createSlice({
  initialState,
  name: 'workspaceSlice',
  reducers: {
    setLeftSideMenuWidth(state, { payload }) {
      state.leftSideMenuWidth = payload;
    },
    removeTemplateFromCurrentCollection(state, { payload }) {
      state.myProjects = state.myProjects.filter(
        (project) =>
          !project.mockup_collections.includes({
            id: payload.activeMockupCollectionId,
          })
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        workspaceApi.endpoints.getCurentUserWorkspaceInfo.matchFulfilled,
        (state, response) => {
          state.currentWorkspace = response.payload.data;
          state.workspaceLoading = false;
        }
      )
      .addMatcher(
        workspaceApi.endpoints.getCurentUserWorkspaceInfo.matchRejected,
        (state, response) => {
          state.workspaceLoading = false;
        }
      )
      .addMatcher(
        workspaceApi.endpoints.getMyProjects.matchFulfilled,
        (state, response) => {
          state.myProjects = response.payload.data;
        }
      )
      .addMatcher(
        workspaceApi.endpoints.deleteMyProject.matchFulfilled,
        (state, response) => {
          state.myProjects = state.myProjects.filter(
            (project) =>
              project.project_id !== response.meta.arg.originalArgs.id
          );
        }
      )
      .addMatcher(
        workspaceApi.endpoints.updateMyProject.matchFulfilled,
        (state, response) => {
          const updatedProject = response.meta.arg.originalArgs;

          state.myProjects = state.myProjects.map((project) =>
            project.id === updatedProject.id
              ? { ...project, ...updatedProject }
              : project
          );
        }
      )
      .addMatcher(
        mockupCollectionsApi.endpoints.addToMockupCollection.matchFulfilled,
        (state, response) => {
          const updatedProject = response.meta.arg.originalArgs;

          state.myProjects = state.myProjects.map((project) => {
            if (project.id === updatedProject.mockup_id) {
              return {
                ...project,
                mockup_collections: [
                  ...project.mockup_collections,
                  ...updatedProject.mockup_collections.map((id) => ({ id })),
                ],
              };
            }
            return project;
          });
        }
      )
      .addMatcher(
        mockupCollectionsApi.endpoints.removeFromMockupCollection
          .matchFulfilled,
        (state, response) => {
          const updatedProject = response.meta.arg.originalArgs;

          state.myProjects = state.myProjects.map((project) => {
            if (project.id === updatedProject.mockup_id) {
              return {
                ...project,
                mockup_collections: project.mockup_collections.filter(
                  (collection) =>
                    !updatedProject.mockup_collections.includes(collection.id)
                ),
              };
            }
            return project;
          });
        }
      )
      .addMatcher(
        mockupCollectionsApi.endpoints.removeFromMockupCollection
          .matchFulfilled,
        (state, response) => {
          if (response.meta.arg.originalArgs.activeMockupCollectionId !== 0) {
            state.myProjects = state.myProjects.filter((project) =>
              JSON.stringify(project.mockup_collections).includes(
                JSON.stringify({
                  id: response.meta.arg.originalArgs.activeMockupCollectionId,
                })
              )
            );
          }
        }
      );
  },
});

export default workspaceSlice.reducer;

export const { setLeftSideMenuWidth, removeTemplateFromCurrentCollection } =
  workspaceSlice.actions;
