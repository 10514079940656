import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    googleAuth: builder.query<any, void>({
      query: () => `auth/google/redirect`,
    }),
    googleAuthCallback: builder.query<any, string>({
      query: (code) => `auth/google/callback${code}`,
    }),
    login: builder.mutation<any, any>({
      query: (payload) => ({
        url: `auth/login`,
        method: 'POST',
        body: payload,
        headers: { Accept: 'application/json' },
      }),
    }),
    register: builder.mutation<any, any>({
      query: (payload) => ({
        url: `auth/register`,
        method: 'POST',
        body: payload,
        headers: { Accept: 'application/json' },
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (payload) => ({
        url: `auth/password-reset/send-link`,
        method: 'POST',
        body: payload,
        headers: { Accept: 'application/json' },
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: (payload) => ({
        url: `auth/password-reset/new-password`,
        method: 'POST',
        body: payload,
        headers: { Accept: 'application/json' },
      }),
    }),
  }),
});

export const {} = authApi;
