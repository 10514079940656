import { Box, Text, Flex, Switch, ScrollArea, Select } from '@radix-ui/themes';
import '../editor.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveView } from '@/redux/slices/editor';
import { ReactComponent as FitModeIcon } from '@/assets/icons/fit-mode.svg';
import { ReactComponent as PrintAreaIcon } from '@/assets/icons/print-area.svg';
import { EditorV2 } from '@/features/components/EditorV2';
import { useEffect, useState } from 'react';
import { psdEngineApi } from '@/services/psdEngine';
import { updateActiveSmartObject } from '@/redux/slices/collection';
import { LocalPlacementPreview } from '../../view-wrapper/components/LocalPlacementPreview';
import { useMediaQuery } from 'react-responsive';
import { assetFit } from '@/services/types';
import { useParams } from 'react-router-dom';
import { psdManagementApi } from '@/services/psdManagement';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import posthog from 'posthog-js';
import useCaptureEvent from '@/hooks/useCaptureEvent';
export interface IAssetFileConfig {
  url: string;
  width: number;
  height: number;
  transformX: number;
  transformY: number;
  smartObjectWidth: number;
  smartObjectHeight: number;
  design_area_width: number;
  design_area_height: number;
  design_area_left: number;
  design_area_top: number;
  rotate: number;
}

export const DesignAsset = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { activeDesignAsset, activeSmartObject, activeVariation } =
    useAppSelector((state) => state.collectionReducer);
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [updateAsset] = psdEngineApi.useUpdateDesignAssetMutation();

  const isAppPro = useFeatureFlagEnabled('app-pro');
  const [generateSingleMockupVariation] =
    psdEngineApi.useGenerateSingleMockupVariationMutation();

  const [assetFileConfig, setAssetFileConfig] = useState<IAssetFileConfig>({
    width: activeDesignAsset.width || 50,
    height: activeDesignAsset.height || 50,
    transformX: activeDesignAsset.left || 0,
    transformY: activeDesignAsset.top || 0,
    design_area_width: activeSmartObject.global_asset_width || 50,
    design_area_height: activeSmartObject.global_asset_height || 50,
    design_area_left: activeSmartObject.global_asset_left || 0,
    design_area_top: activeSmartObject.global_asset_top || 0,
    smartObjectWidth: activeSmartObject?.width,
    smartObjectHeight: activeSmartObject?.height,
    url: activeDesignAsset?.asset_path,
    rotate: activeDesignAsset?.rotate || 0,
  });

  useEffect(() => {
    setAssetFileConfig({
      width: activeDesignAsset.width || 50,
      height: activeDesignAsset.height || 50,
      transformX: activeDesignAsset.left || 0,
      transformY: activeDesignAsset.top || 0,
      design_area_width: activeSmartObject.global_asset_width || 50,
      design_area_height: activeSmartObject.global_asset_height || 50,
      design_area_left: activeSmartObject.global_asset_left || 0,
      design_area_top: activeSmartObject.global_asset_top || 0,
      smartObjectWidth: activeSmartObject?.width,
      smartObjectHeight: activeSmartObject?.height,
      url: activeDesignAsset?.asset_path,
      rotate: activeDesignAsset?.rotate || 0,
    });
  }, [activeDesignAsset]);

  const apiCallUpdateAsset = async (data: Partial<IAssetFileConfig>) => {
    setAssetFileConfig((prevObj) => {
      return {
        ...prevObj,
        ...data,
      };
    });

    if (activeSmartObject.print_area) {
      dispatch(
        updateActiveSmartObject({
          print_area: activeSmartObject?.print_area,
          fit: activeSmartObject?.fit,
          global_asset_top: data.design_area_top,
          global_asset_left: data.design_area_left,
          global_asset_width: data.design_area_width,
          global_asset_height: data.design_area_height,
        })
      );
    }

    await generateSingleMockupVariation({
      mockup_variation_id: activeVariation.id,
      asset_id: activeDesignAsset?.id,
      smart_object_id: activeSmartObject?.id,
      design_area_height: (data.design_area_height as number) || 1,
      design_area_width: (data.design_area_width as number) || 1,
      design_area_top: (data.design_area_top as number) || 1,
      design_area_left: (data.design_area_left as number) || 1,
      height: data.height as number,
      width: data.width as number,
      top: data.transformY as number,
      left: data.transformX as number,
      rotate: data.rotate as number,
      // subscription_type_id: isAppPro ? 2 : 1,
      subscription_type_id: 2,
    });
  };

  const togglePrintArea = async (value: boolean) => {
    try {
      if (value) {
        dispatch(
          updateActiveSmartObject({
            print_area: value ? 1 : 0,
            fit: activeSmartObject?.fit,
            global_asset_top: activeSmartObject.global_asset_top,
            global_asset_left: activeSmartObject.global_asset_left,
            global_asset_width: activeSmartObject.global_asset_width,
            global_asset_height: activeSmartObject.global_asset_height,
          })
        );

        await generateSingleMockupVariation({
          mockup_variation_id: activeVariation.id,
          asset_id: activeDesignAsset?.id,
          smart_object_id: activeSmartObject?.id,
          print_area: value ? 1 : 0,
          fit: activeSmartObject?.fit,
          design_area_width: assetFileConfig.design_area_width || 1,
          design_area_height: assetFileConfig.design_area_height || 1,
          design_area_left: assetFileConfig.design_area_left || 1,
          design_area_top: assetFileConfig.design_area_top || 1,
          rotate: assetFileConfig.rotate,
          // subscription_type_id: isAppPro ? 2 : 1,
          subscription_type_id: 2,
        });
      } else {
        dispatch(
          updateActiveSmartObject({
            print_area: value ? 1 : 0,
            fit: activeSmartObject?.fit,
            global_asset_top: activeSmartObject.global_asset_top || 1,
            global_asset_left: activeSmartObject.global_asset_left || 1,
            global_asset_width: activeSmartObject.global_asset_width || 1,
            global_asset_height: activeSmartObject.global_asset_height || 1,
          })
        );

        await generateSingleMockupVariation({
          mockup_variation_id: activeVariation.id,
          asset_id: activeDesignAsset?.id,
          smart_object_id: activeSmartObject?.id,
          print_area: value ? 1 : 0,
          fit: activeSmartObject?.fit,
          width: assetFileConfig.width,
          height: assetFileConfig.height,
          left: assetFileConfig.transformX,
          top: assetFileConfig.transformY,
          rotate: assetFileConfig.rotate,
          // subscription_type_id: isAppPro ? 2 : 1,
          subscription_type_id: 2,
        });
      }
    } catch (error) {
      console.log('Print area toggle failed: ', error);
      captureEvent('Print area toggle failed', {
        error: error,
      });
    }
  };

  const setPrintAreaFit = async (fit: assetFit) => {
    dispatch(
      updateActiveSmartObject({
        print_area: activeSmartObject?.print_area,
        fit: fit,
        global_asset_top: activeSmartObject.global_asset_top,
        global_asset_left: activeSmartObject.global_asset_left,
        global_asset_width: activeSmartObject.global_asset_width,
        global_asset_height: activeSmartObject.global_asset_height,
      })
    );
    await generateSingleMockupVariation({
      mockup_variation_id: activeVariation.id,
      asset_id: activeDesignAsset?.id,
      smart_object_id: activeSmartObject?.id,
      print_area: activeSmartObject?.print_area,
      fit: fit,
      design_area_width: assetFileConfig.design_area_width || 1,
      design_area_height: assetFileConfig.design_area_height || 1,
      design_area_left: assetFileConfig.design_area_left || 1,
      design_area_top: assetFileConfig.design_area_top || 1,
      rotate: assetFileConfig.rotate,
      // subscription_type_id: isAppPro ? 2 : 1,
      subscription_type_id: 2,
    });
  };

  return (
    <Flex className="design-asset" direction={'column'} align={'start'}>
      <ScrollArea
        type="hover"
        scrollbars="vertical"
        style={{ height: 'calc(100vh - 70px)' }}
      >
        {isMobile && activeView === 'update-asset' && <LocalPlacementPreview />}
        <Flex
          className="editor-wrapper"
          direction={'column'}
          gap={'4'}
          px={'4'}
          pt={'4'}
        >
          <EditorV2
            assetFileConfig={assetFileConfig}
            apiCallUpdateAsset={apiCallUpdateAsset}
          />
          <Flex className="options-wrapper" direction={'column'}>
            <Flex align={'center'} justify={'between'} p={'4'} gap={'4'}>
              <Flex direction={'column'} gap={'1'}>
                <Flex align={'center'} gap={'1'}>
                  <PrintAreaIcon className="icon" width="15" height="15" />{' '}
                  <Text size={'2'} weight={'bold'}>
                    Print Area
                  </Text>
                </Flex>
                <Text size={'1'} weight={'regular'}>
                  Places the design in a free-form defined area.
                </Text>
              </Flex>
              <Box>
                <Switch
                  onCheckedChange={(e) => togglePrintArea(e)}
                  radius="full"
                  checked={!!activeSmartObject?.print_area}
                />
              </Box>
            </Flex>
            {!!activeSmartObject?.print_area && (
              <Flex align={'center'} justify={'between'} p={'4'} gap={'4'}>
                <Flex direction={'column'} gap={'1'}>
                  <Flex align={'center'} gap={'1'}>
                    <FitModeIcon className="icon" width="15" height="15" />{' '}
                    <Text size={'2'} weight={'bold'}>
                      Fit Mode
                    </Text>
                  </Flex>
                  <Text size={'1'} weight={'regular'}>
                    Auto-fits the design in the Print Area.
                  </Text>
                </Flex>

                <Select.Root
                  value={activeSmartObject?.fit}
                  onValueChange={(e) => setPrintAreaFit(e as assetFit)}
                >
                  <Select.Trigger style={{ width: '30%' }} />
                  <Select.Content position="popper" style={{ width: '50%' }}>
                    <Select.Item value="contain">Contain</Select.Item>
                    <Select.Item value="cover">Cover</Select.Item>
                    <Select.Item value="stretch">Stretch</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

