import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  ColorCombination,
  DataModel,
  DataModelArray,
  DeleteAssetResponse,
  DeleteColorResponse,
  DesignAsset,
  DownloadAllStatusResponse,
  GenerateSingleMockupVariation,
  InputOptions,
  Mockup,
  MockupVariationElement,
  SmartObject,
} from './types';
import { EndpointSequentialBaseQuery } from './sequentialBaseQuery';

export const psdEngineApi = createApi({
  reducerPath: 'psdEngineApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    uploadPsdMockup: builder.mutation<DataModel<Mockup>, FormData>({
      query: (payload) => ({
        url: `mockups/create`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    loadPublicPsdMockup: builder.mutation<
      DataModel<Mockup>,
      { psd_id: number }
    >({
      query: (payload) => ({
        url: `mockups/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    getWorkspaceInfo: builder.query<DataModel<Mockup>, void>({
      query: () => `user/workspace-info`,
    }),
    getCurrentMockup: builder.query<DataModel<Mockup>, { mockup_id: number }>({
      query: ({ mockup_id }) => `mockups/${mockup_id}`,
    }),
    getMockupSmartObjects: builder.query<
      DataModelArray<SmartObject>,
      { mockup_id: number }
    >({
      query: ({ mockup_id }) => `mockups/${mockup_id}/smart-objects`,
    }),
    getMockupSmartObjectAssets: builder.query<
      DataModelArray<DesignAsset>,
      { smart_object_id: number }
    >({
      query: ({ smart_object_id }) =>
        `mockups/smart-objects/${smart_object_id}/assets`,
    }),
    getMockupSmartObjectColorCombinations: builder.query<
      DataModelArray<ColorCombination>,
      { smart_object_id: number }
    >({
      query: ({ smart_object_id }) =>
        `mockups/smart-objects/${smart_object_id}/colors`,
    }),
    addColorCombination: builder.mutation<
      DataModel<ColorCombination>,
      Partial<ColorCombination>
    >({
      query: (payload) => ({
        url: `colors`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateColorCombination: builder.mutation<
      DataModel<ColorCombination>,
      Partial<ColorCombination>
    >({
      query: (payload) => ({
        url: `colors/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    removeColorCombination: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `colors/${id}`,
        method: 'DELETE',
      }),
    }),
    addDesignAsset: builder.mutation<DataModelArray<DesignAsset>, FormData>({
      query: (payload) => ({
        url: `assets`,
        method: 'POST',
        body: payload,
        formData: true,
      }),
    }),
    updateDesignAsset: builder.mutation<
      DataModel<DesignAsset>,
      Partial<DesignAsset>
    >({
      query: (payload) => ({
        url: `assets/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getMockupVariations: builder.query<
      DataModelArray<MockupVariationElement>,
      { mockup_id: number }
    >({
      query: ({ mockup_id }) => `mockups/${mockup_id}/variations`,
    }),
    getSequentialMockupVariations: builder.query<
      DataModelArray<MockupVariationElement>,
      { mockup_id: number }
    >({
      queryFn: (args, api, extraOptions) => {
        return EndpointSequentialBaseQuery(
          {
            url: `mockups/${args.mockup_id}/variations`,
            method: 'GET',
          },
          api,
          extraOptions
        );
      },
    }),
    generateSingleMockupVariation: builder.mutation<
      DataModel<MockupVariationElement>,
      Partial<GenerateSingleMockupVariation>
    >({
      query: (payload) => ({
        url: `mockups/generate-single-variation`,
        method: 'POST',
        body: payload,
      }),
    }),
    getSingleMockupVariation: builder.query<
      DataModel<MockupVariationElement>,
      { variation_id: number; subscription_type_id?: number }
    >({
      queryFn: (args, api, extraOptions) => {
        return EndpointSequentialBaseQuery(
          {
            url: `mockups/generate-single-variation`,
            method: 'POST',
            body: {
              mockup_variation_id: args.variation_id,
              // subscription_type_id: args.subscription_type_id,
              subscription_type_id: 2,
            },
          },
          api,
          extraOptions
        );
      },
    }),
    createMockupVariations: builder.mutation<
      DataModelArray<MockupVariationElement>,
      {
        color_id?: number;
        asset_id?: number;
        smart_object_id: number;
        mockup_id: number | string;
      }
    >({
      query: (payload) => ({
        url: `mockups/variations`,
        method: 'POST',
        body: payload,
      }),
      // queryFn: (args, api, extraOptions) => {
      //   return EndpointSequentialBaseQuery(
      //     {
      //       url: `mockups/variations`,
      //       method: 'POST',
      //       body: {
      //         color_id: args.color_id || undefined,
      //         asset_id: args.asset_id || undefined,
      //         smart_object_id: args.smart_object_id,
      //         mockup_id: args.mockup_id,
      //       },
      //     },
      //     api,
      //     extraOptions
      //   );
      // },
    }),
    deleteAssetVariation: builder.mutation<
      DataModel<DeleteAssetResponse>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `mockups/variations/delete-asset `,
        method: 'POST',
        body: { asset_id: id },
      }),
    }),
    deleteColorVariation: builder.mutation<
      DataModel<DeleteColorResponse>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `mockups/variations/delete-color `,
        method: 'POST',
        body: { color_id: id },
      }),
    }),
    downloadAll: builder.mutation<
      DataModel<{ task_id: string }>,
      { id: number; format: string; max_size: number }
    >({
      query: (payload) => ({
        url: `mockups/${payload.id}/variations/download-all`,
        method: 'POST',
        body: payload,
      }),
    }),
    singleDownload: builder.mutation<
      DataModel<{ export_path: string }>,
      {
        mockup_variation_id: number;
        format: string;
        max_size: number;
        subscription_type_id?: number;
      }
    >({
      query: (payload) => ({
        url: `mockups/download-single-variation`,
        method: 'POST',
        body: payload,
      }),
    }),
    getSingleUrl: builder.mutation<
      DataModel<string>,
      {
        mockup_variation_id: number;
        format: string;
        max_size: number;
        subscription_type_id?: number;
      }
    >({
      query: (payload) => ({
        url: `mockups/get-single-variation-image-url`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDownloadAllStatus: builder.query<
      DataModel<DownloadAllStatusResponse>,
      { id: string }
    >({
      query: ({ id }) => `mockups/variations/${id}/download-all-status`,
    }),
    createColorPreview: builder.mutation<
      DataModel<string>,
      {
        smart_object_id: number;
        hex_code?: string;
        mockup_variation_id?: number;
        subscription_type_id?: number;
      }
    >({
      query: (payload) => ({
        url: `mockups/generate-one-time-single-variation`,
        method: 'POST',
        body: payload,
      }),
    }),
    // updateSmartObject: builder.mutation<
    //   DataModel<SmartObject>,
    //   { data: Partial<SmartObject>; id: number }
    // >({
    //   query: ({ id, data }) => ({
    //     url: `mockups/smart-objects/${id}/update-print-area`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
    updateSmartObjectInputOptions: builder.mutation<
      DataModel<SmartObject>,
      { soId: number; data: InputOptions }
    >({
      query: (payload) => ({
        url: `smart-object-input-options/update/${payload.soId}`,
        method: 'PUT',
        body: payload.data,
      }),
    }),
  }),
});

export const {} = psdEngineApi;
