import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { ApiKey } from '@/redux/slices/types';
import { DataModel, DataModelArray } from './types';

export const apiKeyApi = createApi({
  reducerPath: 'apiKeyApi',
  baseQuery: baseQuery,
  tagTypes: ['getApiKeys'],
  endpoints: (builder) => ({
    getApiKeys: builder.query<DataModelArray<ApiKey>, void>({
      query: () => `api-key/keys`,
      providesTags: ['getApiKeys'],
    }),
    createApiKey: builder.mutation<DataModel<ApiKey>, { name: string }>({
      query: (payload) => ({
        url: `api-key/keys`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['getApiKeys'],
    }),
    updateApiKey: builder.mutation<DataModel<ApiKey>, Partial<ApiKey>>({
      query: (payload) => ({
        url: `api-key/keys/${payload.uuid}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['getApiKeys'],
    }),
    deleteApiKey: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `api-key/keys/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getApiKeys'],
    }),
  }),
});

export const {} = apiKeyApi;
