import './auth.scss';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from '@/hooks';
import { authApi } from '@/services/auth';
import { ReactComponent as DesignCopilotLogo } from '@/assets/design-copilot-logo.svg';
import { Flex, Text, TextField, Button, Heading } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setForgotPasswordSuccessEmail } from '@/redux/slices/auth';

interface IFormInput {
  email: string;
}

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    forgotPasswordSubmit(data);

  const forgotPasswordSubmit = async (data: IFormInput) => {
    try {
      captureEvent('Forgot password initiated', { email: data.email });
      setIsLoading(true);
      const inputs = {
        email: data.email,
      };
      await dispatch(
        authApi.endpoints.forgotPassword.initiate(inputs)
      ).unwrap();
      resetField('email');
      captureEvent('Forgot password sent link', { email: data.email });
      dispatch(setForgotPasswordSuccessEmail(data.email));
      navigate(`/forgot-password/success`);
    } catch (error: any) {
      console.log(error.data);

      toast.error(error.data.message, {
        toastId: 'forgot_password_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dynamic Mockups | Forgot your password</title>
        <link
          rel="canonical"
          href="https://app.dynamicmockups.com/forgot-password/"
        />
      </Helmet>

      <Flex
        className="auth-page"
        align="center"
        justify="center"
        direction={'column'}
        style={{
          height: '100%',
        }}
      >
        <Flex
          className="header"
          direction={'column'}
          align={'center'}
          gap={'2'}
        >
          <DesignCopilotLogo className="logo" />
          <Heading as={'h1'}>Reset your password</Heading>
        </Flex>
        <Flex
          className="content"
          direction={'column'}
          justify={'center'}
          width={'400px'}
        >
          <Flex className="email-pass" direction={'column'}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
              }}
            >
              <TextField.Root
                placeholder="Email Address"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email field is required',
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match Email format',
                  },
                })}
              />

              {errors.email && (
                <Text role="alert" size={'1'} color="red">
                  {errors.email.message}
                </Text>
              )}
              <Button
                mt={'5'}
                className="login-btn"
                type="submit"
                loading={isLoading}
              >
                Send reset link
              </Button>
            </form>

            <Text className="dont-have-acc" weight={'regular'} size={'1'}>
              Go back to{' '}
              <Text
                weight={'medium'}
                size={'1'}
                onClick={() => navigate(`/login`)}
              >
                Login
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex className="footer">
          <Text weight={'regular'} size={'1'}>
            By using Dynamic Mockups, you are agreeing to our{' '}
            <a
              href="https://dynamicmockups.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://dynamicmockups.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
